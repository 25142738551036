import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
import {
  changeTab,
  toggleShowAdvanced,
} from '../../../../redux/businesses/businessSlice'

interface Props {}

const BusinessesHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const translations = useMenuTranslations()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleBusinessesHelpTour = useCallback(() => {
    dispatch(toggleShowAdvanced(false))
    dispatch(startTour('businesses'))
    navigate('/businesses')
    dispatch(changeTab('decisions'))
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.businesses} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleBusinessesHelpTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'BUSINESS DECISIONS'}
            content={
              "This screen presents the core financial products and services offered by your bank. Here, you'll make crucial decisions about interest rates, fees, support levels, and other key parameters that define your offerings. Each section represents a major area of banking operations, allowing you to fine-tune your strategy across various product lines. Use the interactive controls to adjust your settings, always keeping in mind how these changes will affect your bank's attractiveness to customers and overall profitability. Your decisions here will shape your bank's market position and competitive edge against rival institutions."
            }
            hint={
              "Balance is key. Aggressive rates may attract customers quickly, but could hurt long-term profitability. Consider how each decision affects both your market share and your bottom line. Keep an eye on your competitors' moves and be ready to adapt your strategy accordingly."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'PRICE & VOLUME TRADE-OFF'}
            content={
              "This screen illustrates the relationship between pricing decisions and business volume across your bank's product lines. You can adjust prices and see how these changes impact the estimated volume and income for each product. The graph at the top provides a visual representation of the price-volume trade-off for home loans, allowing you to compare current and previous year's data."
            }
            hint={
              'Finding the sweet spot between price and volume is crucial. A small price change can significantly affect your volume and overall income. Consider market elasticity and competitor pricing when making adjustments'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'ADVANCED MODE'}
            content={
              "Advanced Mode provides a comprehensive view of your bank's financial structure. It breaks down assets and liabilities, showing interest rates, forecast volumes, and interest for each product category. This detailed overview includes total interest income, expenses, and calculates your net interest margin. Use this mode to fine-tune your strategy and understand the broader impact of your decisions on the bank's overall financial health."
            }
            hint={
              'Pay close attention to the relationship between assets and liabilities. Balancing high-yielding assets with cost-effective funding sources can significantly improve your net interest margin. Watch for opportunities to optimize your product mix and pricing strategies.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default BusinessesHelp
