import React, { useEffect } from 'react'
import { fetchNews } from '../../../redux/news/newsActions'
import { useAppDispatch, useAppSelector } from '../../../store'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import { fetchSummary } from '../../../redux/summary/summaryActions'
import { CreditRating } from '../../../types/gameApi/enums'
import { fetchBusinesses } from '../../../redux/businesses/businessActions'
import BreakingNews from './BreakingNews'
import { EconomicScenarioNewsRoundResponse } from '../../../types/gameApi/news'

interface Props {}

const BreakingNewsContainer: React.FC<Props> = () => {
  const {
    isLoading,
    news,
    summary,
    summaryLoading,
    businesses,
    businessesLoading,
    teamId,
    roundId,
  } = useAppSelector(state => ({
    isLoading: state.news.isLoading,
    news: state.news.news,
    summaryLoading: state.summary.isLoading,
    summary: state.summary.summaries,
    businessesLoading: state.businesses.isLoading,
    businesses: state.businesses.data,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
  }))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (teamId > 0 && roundId > 0 && !news && !isLoading) {
      dispatch(fetchNews({}))
    }
    if (teamId > 0 && roundId > 0 && !summary[roundId] && !summaryLoading) {
      dispatch(fetchSummary({ roundId, teamId }))
    }
    if (
      teamId > 0 &&
      roundId > 0 &&
      !businesses[roundId] &&
      !businessesLoading
    ) {
      dispatch(fetchBusinesses({ roundId, teamId }))
    }
  }, [
    businesses,
    businessesLoading,
    dispatch,
    isLoading,
    news,
    roundId,
    summary,
    summaryLoading,
    teamId,
  ])

  const newsForRound: EconomicScenarioNewsRoundResponse | null =
    news?.rounds.find(r => r.roundId === roundId) ?? null

  if (
    isLoading ||
    !news ||
    !newsForRound ||
    !summary[roundId] ||
    !businesses[roundId]
  ) {
    return <CentredSpinner />
  }

  const businessForRounds = businesses[roundId]
  const data: EconomicScenarioNewsRoundResponse = {
    ...newsForRound,
    marketOutlook: newsForRound.marketOutlook.filter(mo =>
      businessForRounds.businesses.some(b => b.type === mo.productType),
    ),
  }
  const creditRating = summary[roundId].find(s => s.type === 'Rating')
  const creditRatingValue: CreditRating = creditRating?.value
    ? (creditRating?.value.value as unknown as CreditRating)
    : 'A'

  return (
    <BreakingNews
      data={data}
      newsConfig={news.newsConfig}
      creditRating={creditRatingValue}
      roundId={roundId}
    />
  )
}

export default BreakingNewsContainer
