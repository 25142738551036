import { createSlice } from '@reduxjs/toolkit'
import {
  fetchProjects,
  fetchProjectsBackground,
  removeProjectDecision,
  updateProjectDecision,
} from './projectsActions'
import APIError from '../../errors/APIError'
import { ProjectsResponse } from '../../types/gameApi/projects'
import { reset } from '../game/gameSlice'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

interface ProjectState {
  isLoading: boolean
  isLoaded: boolean
  isLoadingBackground: boolean
  projects: {
    [roundId: number]: ProjectsResponse
  }
  error: Error | APIError | null
  isSubmitting: boolean
  isDeleting: boolean
  requestInProgress: string | null
}

const initialState: ProjectState = {
  isLoading: false,
  isLoaded: false,
  isLoadingBackground: false,
  projects: {},
  error: null,
  isSubmitting: false,
  isDeleting: false,
  requestInProgress: null,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchProjects.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchProjects.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.projects[payload.roundId] = payload.projects
        state.requestInProgress = null
      })
      .addCase(fetchProjects.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(updateProjectDecision.pending, state => {
        state.isSubmitting = true
        state.error = null
      })
      .addCase(updateProjectDecision.fulfilled, (state, { payload }) => {
        state.isSubmitting = false
        state.error = null
        state.projects[payload.roundId] = state.projects[payload.roundId].map(
          project => {
            if (project.id === payload.projectId) {
              return {
                ...project,
                usedInRound: payload.roundId,
                businessId: payload.businessId || 0,
              }
            }
            if (project.usedInRound === payload.roundId) {
              return { ...project, usedInRound: null, businessId: 0 }
            }
            return project
          },
        )
      })
      .addCase(updateProjectDecision.rejected, (state, { payload }) => {
        state.isSubmitting = false
        state.error = payload ?? null
      })
      .addCase(removeProjectDecision.pending, state => {
        state.isDeleting = true
        state.error = null
      })
      .addCase(removeProjectDecision.fulfilled, (state, { payload }) => {
        state.isDeleting = false
        state.error = null
        state.projects[payload.roundId] = state.projects[payload.roundId].map(
          project => {
            if (project.usedInRound === payload.roundId) {
              return { ...project, usedInRound: null, businessId: 0 }
            }
            return project
          },
        )
      })
      .addCase(removeProjectDecision.rejected, (state, { payload }) => {
        state.isDeleting = false
        state.error = payload ?? null
      })
      .addCase(fetchProjectsBackground.pending, (state, { meta }) => {
        if (!state.isLoading && !state.isLoadingBackground) {
          state.requestInProgress = meta.requestId
          state.isLoadingBackground = true
        }
      })
      .addCase(fetchProjectsBackground.fulfilled, (state, { payload }) => {
        state.isLoadingBackground = false
        if (process.env.REACT_APP_DEMO_MODE !== 'true') {
          state.projects[payload.roundId] = payload.projects
        }
        state.requestInProgress = null
      })
      .addCase(fetchProjectsBackground.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoadingBackground = false
        state.requestInProgress = null
      })
  },
})

export default projectsSlice.reducer
