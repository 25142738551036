import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
interface Props {}
const SummaryHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()

  const handleSummaryTour = useCallback(() => {
    dispatch(startTour('summary'))
    navigate('/summary')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.summary} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleSummaryTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'KEY METRICS OVERVIEW'}
            content={
              "The Summary Dashboard is your command center for tracking your bank's performance across a range of critical metrics. This at-a-glance view allows you to quickly assess your current standing and progress over each round."
            }
            hint={
              'Pay special attention to the metrics designated with the trophy icon. These "winning metrics" are the key drivers of your overall performance and are weighted heavily in determining your success.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'METRIC TILE BREAKDOWN'}
            content={
              'Each metric tile contains several key elements: the metric name, current score, score change, competitive ranking, ranking change, and winning metric indicator (if applicable).'
            }
            hint={
              'Familiarize yourself with these elements to quickly interpret your performance and make informed decisions based on the data.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default SummaryHelp
