import React from 'react'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import Text from '../../atoms/Text/Text'
import { Icon, IconType } from '../../atoms/Icon/Icon'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'

interface InAppHelpContentProps {
  heading: string
  children: React.ReactNode
  isWarning?: boolean
  tip?: string
  spendTime?: string
  headingIcon?: IconType
}

const InAppHelpContent: React.FC<InAppHelpContentProps> = ({
  heading,
  children,
  isWarning = false,
  tip,
  spendTime,
  headingIcon = isWarning ? 'warning' : 'info',
}) => (
  <VerticalGroup start gap={2}>
    <HorizontalGroup verticalCenter fullWidth gap={1}>
      <Icon
        size={10}
        colour={isWarning ? 'orange' : 'primary'}
        type={headingIcon}
      />
      <Text left weight="bold" size="xl">
        {heading}
      </Text>
    </HorizontalGroup>
    {spendTime && (
      <HorizontalGroup fullWidth verticalCenter>
        <Icon type="clock" colour="primary" size={5} />
        <HorizontalGroup gap={1}>
          <Text colour="primary">Spend:</Text>
          {spendTime}
        </HorizontalGroup>
      </HorizontalGroup>
    )}
    {children}
    {tip && (
      <Text left size="sm" className="bg-orange-100 p-2">
        Tip: {tip}
      </Text>
    )}
  </VerticalGroup>
)

export default InAppHelpContent
