import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'

import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
import { changeTab } from '../../../../redux/treasury/treasurySlice'

interface Props {}
const TreasuryHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()

  const handleTreasuryTour = useCallback(() => {
    dispatch(startTour('treasury'))
    dispatch(changeTab('balance-sheet'))
    navigate('/treasury')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.treasury} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleTreasuryTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={`${translations.treasury.toUpperCase()} OVERVIEW`}
            content={
              "This interface provides a comprehensive set of tools for overseeing and optimizing your financial institution's operations. It offers features that allow you to forecast, plan, and make informed decisions about your institution's financial future."
            }
            hint={
              "This interface offers a range of features to support your institution's financial management. From assessing your current position to planning for the future, these tools are designed to help you make informed decisions. As you explore, consider how each feature can contribute to your short-term needs and long-term goals. For a detailed walkthrough of all available features, use the guided tour option to maximize the benefits for your institution."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default TreasuryHelp
