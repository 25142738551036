import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import InlineError from '../../atoms/InlineError/InlineError'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import Costs from './Costs'
import { v4 as uuidv4 } from '../../../lib/uuid'
import { RootState } from '../../../store'

import Page from '../../atoms/Page/Page'
import {
  CostsDecisionUpdate,
  CostsDivisionType,
  CostsResponse,
} from '../../../types/gameApi/costs'
import {
  fetchCosts,
  fetchCostsBackground,
  updateCostsDecisionsBackground,
} from '../../../redux/costs/costsActions'
import { fteCostPerDivision, getImpacts } from './calculations'
import { fetchSummary } from '../../../redux/summary/summaryActions'

const anyVersionsChanged = (
  newData: CostsResponse,
  oldData: CostsResponse,
  deviceId: string,
) => {
  let hasChange = false
  if (
    newData.version !== oldData.version &&
    newData.version > oldData.version &&
    newData.updatedBy &&
    newData.updatedBy !== deviceId
  ) {
    hasChange = true
  }
  return hasChange
}

const checkChange = (
  fieldName: keyof DecisionMap,
  decisions: DecisionMap,
  lastSynced: DecisionMap,
  changes: CostsDecisionUpdate[],
  apiFieldName?: string,
) => {
  const newVal = parseInt(String(decisions[fieldName]))
  if (newVal !== lastSynced[fieldName]) {
    changes.push({ [apiFieldName || fieldName]: newVal })
    lastSynced[fieldName] = newVal
  }
}

const mapState = (state: RootState) => {
  const selectedRound = state.game.selectedRound
  return {
    enableChanges: state.game.selectedRound === state.game.currentRound,
    teamNumber: state.team.id ?? 0,
    selectedRound,
    roundNumber: state.game.currentRound,
    isLoading: state.costs.isLoading,
    isLoaded: state.costs.isLoaded,
    error: state.costs.error,
    costs: state.costs.data[selectedRound] ?? null,
    summaries: state.summary.summaries,
  }
}

const mapDispatch = {
  fetchCosts,
  updateCostsDecisionsBackground,
  fetchCostsBackground,
  fetchSummary,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {}

type State = {
  decisions: DecisionMap | null
  readyForDisplay: boolean
  uniqueDeviceId: string
  forceRerender?: number
}

export interface DecisionMap extends Record<CostsDivisionType, number> {
  employeeEngagementClosingScore: number
  npsClosingScore: number
  complaintsClosing: number
  riskIncidentsClosing: number
  regulatorActionsClosing: number
  communityTrustClosing: number
  fteChangeDivision1: number
  fteChangeDivision2: number
  fteChangeDivision3: number
  fteChangeDivision4: number
  salaryChange: number
}

class CostsContainer extends Component<Props, State> {
  lastSynced: DecisionMap | null = null
  private interval: NodeJS.Timeout | null = null
  constructor(props: Props) {
    super(props)
    this.state = {
      decisions: null,
      readyForDisplay: false,
      uniqueDeviceId: uuidv4(),
    }
  }

  componentDidMount() {
    if (this.props.costs) {
      this.setReadyForDisplay()
      this.props.fetchCostsBackground({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    } else {
      this.props.fetchCosts({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    if (!this.props.summaries[this.props.selectedRound]) {
      this.props.fetchSummary({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    this.pollChanges()
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
      this.checkAndUpdate()
      this.lastSynced = null
    }
  }

  pollChanges = () => {
    this.interval = setInterval(() => {
      this.checkAndUpdate()
      this.props.fetchCostsBackground({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }, 5000)
  }

  getDerivedState = (
    data: CostsResponse,
    decisions: Record<CostsDivisionType, number>,
  ) => {
    const {
      employeeEngagement,
      nps,
      complaints,
      riskIncidents,
      regulatorActions,
      communityTrust,
    } = getImpacts(data, decisions)
    const peopleAndCostImpactPerDivision = fteCostPerDivision(data, decisions)
    return {
      employeeEngagementClosingScore: employeeEngagement.value,
      npsClosingScore: nps.value * 100,
      complaintsClosing: complaints.value,
      riskIncidentsClosing: riskIncidents.value,
      regulatorActionsClosing: regulatorActions.value,
      communityTrustClosing: communityTrust.value,
      fteChangeDivision1: Number(
        peopleAndCostImpactPerDivision[0].fteChange.toFixed(0),
      ),
      fteChangeDivision2: Number(
        peopleAndCostImpactPerDivision[1].fteChange.toFixed(0),
      ),
      fteChangeDivision3: Number(
        peopleAndCostImpactPerDivision[2].fteChange.toFixed(0),
      ),
      fteChangeDivision4: Number(
        peopleAndCostImpactPerDivision[3].fteChange.toFixed(0),
      ),
      salaryChange: Number(
        peopleAndCostImpactPerDivision[4].changeInCost.toFixed(0),
      ),
    }
  }

  setReadyForDisplay = () => {
    this.lastSynced = {
      employeeEngagementClosingScore:
        this.props.costs.employeeEngagementClosingScore,
      npsClosingScore: this.props.costs.npsClosingScore,
      complaintsClosing: this.props.costs.complaintsClosing,
      riskIncidentsClosing: this.props.costs.riskIncidentsClosing,
      regulatorActionsClosing: this.props.costs.regulatorActionsClosing,
      communityTrustClosing: this.props.costs.communityTrustClosing,
      fteChangeDivision1: this.props.costs.fteChangeDivision1,
      fteChangeDivision2: this.props.costs.fteChangeDivision2,
      fteChangeDivision3: this.props.costs.fteChangeDivision3,
      fteChangeDivision4: this.props.costs.fteChangeDivision4,
      salaryChange: this.props.costs.salaryChange,
      IT_OPERATIONS: 0,
      FRONT_LINE: 0,
      RISK_COMPLIANCE: 0,
      HR_TRAINING: 0,
      SALARY_GROWTH: 0,
    }
    const decisions = this.props.costs.divisions.reduce<
      Record<CostsDivisionType, number>
    >(
      (result, division) => {
        const val = division.value_change // this.props.opex[`division${division.id}Change`]
        if (this.lastSynced != null) {
          this.lastSynced[division.type] = val
        }
        result[division.type] = val
        return result
      },
      {
        IT_OPERATIONS: 0,
        FRONT_LINE: 0,
        RISK_COMPLIANCE: 0,
        HR_TRAINING: 0,
        SALARY_GROWTH: 0,
      },
    )

    const derivedState = this.getDerivedState(this.props.costs, decisions)
    const mergedDecisions = { ...decisions, ...derivedState }
    this.setState({
      readyForDisplay: true,
      decisions: mergedDecisions,
    })
  }

  checkAndUpdate = () => {
    const changes: CostsDecisionUpdate[] = []
    if (this.state.decisions == null) {
      return
    }
    const decisions: DecisionMap = { ...this.state.decisions }

    if (this.lastSynced == null) {
      return
    }

    const { divisions } = this.props.costs

    for (const division of divisions) {
      checkChange(
        division.type,
        decisions,
        this.lastSynced,
        changes,
        `division${division.id}Change`,
      )
    }

    checkChange(
      'employeeEngagementClosingScore',
      decisions,
      this.lastSynced,
      changes,
      'employeeEngagementClosingScore',
    )
    checkChange(
      'npsClosingScore',
      decisions,
      this.lastSynced,
      changes,
      'npsClosingScore',
    )
    checkChange(
      'complaintsClosing',
      decisions,
      this.lastSynced,
      changes,
      'complaintsClosing',
    )
    checkChange(
      'riskIncidentsClosing',
      decisions,
      this.lastSynced,
      changes,
      'riskIncidentsClosing',
    )
    checkChange(
      'regulatorActionsClosing',
      decisions,
      this.lastSynced,
      changes,
      'regulatorActionsClosing',
    )
    checkChange(
      'communityTrustClosing',
      decisions,
      this.lastSynced,
      changes,
      'communityTrustClosing',
    )
    checkChange(
      'fteChangeDivision1',
      decisions,
      this.lastSynced,
      changes,
      'fteChangeDivision1',
    )
    checkChange(
      'fteChangeDivision2',
      decisions,
      this.lastSynced,
      changes,
      'fteChangeDivision2',
    )
    checkChange(
      'fteChangeDivision3',
      decisions,
      this.lastSynced,
      changes,
      'fteChangeDivision3',
    )
    checkChange(
      'fteChangeDivision4',
      decisions,
      this.lastSynced,
      changes,
      'fteChangeDivision4',
    )
    checkChange(
      'salaryChange',
      decisions,
      this.lastSynced,
      changes,
      'salaryChange',
    )

    if (changes.length) {
      this.props.updateCostsDecisionsBackground({
        teamId: this.props.teamNumber,
        roundId: this.props.selectedRound,
        decisions: changes,
        deviceId: this.state.uniqueDeviceId,
      })
    }
  }

  retry = () => {
    if (!this.props.summaries[this.props.selectedRound]) {
      this.props.fetchSummary({
        teamId: this.props.teamNumber,
        roundId: this.props.selectedRound,
      })
    }
    this.props.fetchCosts({
      roundId: this.props.selectedRound,
      teamId: this.props.teamNumber,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.selectedRound !== prevProps.selectedRound ||
      this.props.roundNumber !== prevProps.roundNumber ||
      this.props.teamNumber !== prevProps.teamNumber
    ) {
      this.lastSynced = null
      if (!this.props.summaries[this.props.selectedRound]) {
        this.props.fetchSummary({
          teamId: this.props.teamNumber,
          roundId: this.props.selectedRound,
        })
      }
      return this.props.fetchCosts({
        roundId: this.props.selectedRound,
        teamId: this.props.teamNumber,
      })
    }
    const shouldUpdateDecisions =
      (this.props.costs && !prevProps.costs) ||
      (this.props.costs &&
        !prevState.readyForDisplay &&
        !this.state.readyForDisplay) ||
      (this.props.costs &&
        prevProps.costs &&
        anyVersionsChanged(
          this.props.costs,
          prevProps.costs,
          prevState.uniqueDeviceId,
        )) ||
      (this.props.costs && !this.lastSynced)

    if (shouldUpdateDecisions) {
      this.setReadyForDisplay()
    }
  }

  handleChangeValue = (
    changes: Partial<DecisionMap>,
    forceRerender?: number,
  ) => {
    if (this.state.decisions == null) {
      return
    }
    const stateToUpdate: Partial<State> = {
      decisions: {
        ...this.state.decisions,
        ...changes,
      },
    }

    if (forceRerender) {
      stateToUpdate.forceRerender = forceRerender
    }
    // @ts-expect-error not sure how to make this typesafe
    this.setState(stateToUpdate)
  }

  render() {
    if (this.props.error) {
      return (
        <Page full>
          <InlineError
            message={this.props.error.message}
            onRetry={!this.props.costs ? this.retry : undefined}
          />
        </Page>
      )
    }
    if (
      this.props.isLoading ||
      !this.state.readyForDisplay ||
      !this.state.decisions
    ) {
      return <CentredSpinner />
    }
    if (!this.props.costs) {
      return (
        <InlineError
          message={`No opex data has been uploaded for round ${this.props.selectedRound}`}
        />
      )
    }
    return (
      <Costs
        data={this.props.costs}
        // selectedRound={this.props.selectedRound}
        decisions={this.state.decisions}
        onChangeValue={this.handleChangeValue}
        // currentPage={this.props.currentPage}
        // updatePage={this.props.updatePage}
        enableChanges={this.props.enableChanges}
      />
    )
  }
}

export default connector(CostsContainer)
