import React from 'react'
import { Swiper as SwiperComponent, SwiperProps } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper as SwiperType } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

import CustomNavigationArrow from '../CustomNavigationArrow/CustomNavigationArrow'

interface Props extends SwiperProps {
  children: React.ReactNode
  enableFractionPagination?: boolean
  fixedArrows?: boolean
  handleSlideChange?: (swiper: SwiperType) => void
}

const Swiper: React.FC<Props> = ({
  children,
  enableFractionPagination,
  fixedArrows = false,
  handleSlideChange,
  ...restProps
}) => {
  const swiperRef = React.useRef<SwiperType | null>(null)
  const [isFirstSlide, setIsFirstSlide] = React.useState(true)
  const [isLastSlide, setIsLastSlide] = React.useState(false)

  const modules = [Navigation]

  if (enableFractionPagination) {
    modules.push(Pagination)
  }

  return (
    <>
      <CustomNavigationArrow
        direction="previous"
        onClick={() => swiperRef.current?.slidePrev()}
        disabled={isFirstSlide}
        arrowPosition={fixedArrows ? 'fixed' : 'normal'}
      />
      <SwiperComponent
        {...restProps}
        onSwiper={(swiper: SwiperType) => {
          if (swiper !== null) {
            swiperRef.current = swiper
          }
        }}
        onSlideChange={swiper => {
          setIsFirstSlide(swiper.isBeginning)
          setIsLastSlide(swiper.isEnd)
          if (handleSlideChange) {
            handleSlideChange(swiper)
          }
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore for some reason the types are not working
        modules={modules}
        pagination={enableFractionPagination ? { type: 'fraction' } : undefined}
      >
        {children}
      </SwiperComponent>
      <CustomNavigationArrow
        direction="next"
        onClick={() => swiperRef.current?.slideNext()}
        disabled={isLastSlide}
        arrowPosition={fixedArrows ? 'fixed' : 'normal'}
      />
    </>
  )
}
export default Swiper
