import MockAdminAPI from './demo/adminApi/adminApi'

import { get, post } from '../lib/request'
import { LoginParams, LoginResponse } from '../types/adminApi/auth'
import {
  EventResponse,
  ParticipantDetailsRequest,
  ParticipantDetailsResponse,
  TeamParticipantsRequestParams,
  TeamParticipantsResponse,
} from '../types/adminApi/events'
import { ResourceRequest, ResourceResponse } from '../types/adminApi/resources'
import { AssessmentPreviewRequestParams } from '../types/adminApi/assessments'
import { ConnectivityResults } from '../types/adminApi/connectivityCheck'
import {
  EconomicScenarioNewsResponse,
  EconomicScenarioPreviewRequestParams,
} from '../types/gameApi/news'

export const HOST = process.env.REACT_APP_ADMIN_API_URL?.includes('localhost')
  ? process.env.REACT_APP_ADMIN_API_URL.replace(
      'localhost',
      process.env.REACT_APP_LOCAL_IP ?? 'localhost',
    )
  : process.env.REACT_APP_ADMIN_API_URL

class AdminAPI {
  token: string | null
  eventId: string | null

  constructor() {
    this.token = null
    this.eventId = null
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  })

  setToken = (token: string | null) => {
    this.token = token
  }

  setEventId = (eventId: string | null) => {
    this.eventId = eventId
  }

  login = async ({ eventId, email, password }: LoginParams) => {
    return await post<LoginResponse>({
      url: `${HOST}/api/events/${eventId}/login`,
      body: {
        email,
        password,
      },
    })
  }

  getEventsForParticipant = async (email: string) => {
    const response = await get<EventResponse[]>({
      url: `${HOST}/api/participants/${email}/events`,
    })
    return response.data
  }

  getParticipantDetails = async ({
    participantId,
    eventId,
  }: ParticipantDetailsRequest) => {
    const response = await get<ParticipantDetailsResponse>({
      url: `${HOST}/api/events/${eventId}/participants/${participantId}/details`,
      headers: this.headers(),
    })
    return response.data
  }

  getResources = async ({ participantId, eventId }: ResourceRequest) => {
    const response = await get<ResourceResponse>({
      url: `${HOST}/api/events/${eventId}/participants/${participantId}/resources`,
      headers: this.headers(),
    })
    return response.data
  }

  getParticipants = async ({ teamId }: TeamParticipantsRequestParams) => {
    const response = await get<TeamParticipantsResponse>({
      url: `${HOST}/api/events/${this.eventId}/teams/${teamId}/participants`,
      headers: this.headers(),
    })
    return response.data
  }

  getAssessmentPreview = async ({
    assessmentId,
    token,
  }: AssessmentPreviewRequestParams) => {
    const response = await get<any>({
      url: `${HOST}/api/assessments/${assessmentId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }

  getScenarioPreview = async ({
    scenarioId,
    token,
    country,
    currency,
    centralBank,
    regulator,
    referenceRate,
  }: EconomicScenarioPreviewRequestParams) => {
    const response = await get<EconomicScenarioNewsResponse>({
      url: `${HOST}/api/economic-scenarios/${scenarioId}/news?country=${country}&currency=${currency}&centralBank=${centralBank}&regulator=${regulator}&referenceRate=${referenceRate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }

  ping = async () => {
    const response = await get<any>({
      url: `${HOST}/`,
    })
    if (response.data.ok !== true) {
      throw new Error('Server responded, but status is not ok.')
    }

    return response.data
  }
  sendConnectivityResults = async (results: ConnectivityResults) => {
    return await post({
      url: `${HOST}/api/connectivity/results`,
      body: results,
      headers: this.headers(),
    })
  }
}

const api =
  process.env.REACT_APP_DEMO_MODE === 'true' ? MockAdminAPI : new AdminAPI()

export default api
