import React, { useEffect, useState, useCallback } from 'react'

import {
  fetchProjects,
  fetchProjectsBackground,
  removeProjectDecision,
  updateProjectDecision,
} from '../../../redux/projects/projectsActions'
import { useAppDispatch, useAppSelector } from '../../../store'
import Page from '../../atoms/Page/Page'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import SpecialProjectThumbnail from './SpecialProjectThumbnail'
import PrimaryText from '../../atoms/Text/PrimaryText'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import ProjectSelector from './ProjectSelector'
import Modal from '../../atoms/Modal/Modal'
import classNames from 'classnames'
import { Combobox } from '@headlessui/react'
import {
  Project,
  ProjectImpact,
  ProjectOption,
} from '../../../types/gameApi/projects'
import InlineError from '../../atoms/InlineError/InlineError'
import { usePrevious } from 'react-use'
import Spinner from '../../atoms/Spinner/Spinner'

import SortModal from './SortModal'
import ProjectSelectModal from './ProjectSelectModal'
import ProjectDetails from './ProjectDeatails'
import { PROJECT_TYPE_DURATION } from './numberOfYears'
import { useIsFeatureEnabledForRound } from '../../../hooks/useIsFeatureEnabled'
import SpecialProjectsTour from './SpecialProjectsTour'

const SpecialProjects = () => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedImpact, setSelectedImpact] = useState<
    ProjectImpact | undefined
  >(undefined)

  const [sortByDeliveryTime, setSortByDeliveryTime] = useState(false)

  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null,
  )
  const [allProjectsVisible, setAllProjectsVisible] = useState(true)
  const {
    isSubmitting,
    isLoading,
    error,
    projects,
    teamId,
    roundId,
    canSelect,
    demoEnabled,
    demoEnabledImpacts,
  } = useAppSelector(state => ({
    isSubmitting: state.projects.isSubmitting || state.projects.isDeleting,
    isLoading: state.projects.isLoading,
    error: state.projects.error,
    projects: state.projects.projects,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
    canSelect: state.game.selectedRound === state.game.currentRound,
    demoEnabled: state.demo.enabled,
    demoEnabledImpacts: state.demo.enabledImpacts,
  }))

  const isProjectsEnabled = useIsFeatureEnabledForRound('special-projects')

  const previousVals = usePrevious({ isSubmitting })

  const handleSelectClick = () => {
    if (!projects[roundId].find(p => p.usedInRound === roundId)) {
      handleOKClick()
    } else {
      setIsModalOpen(true)
    }
  }

  useEffect(() => {
    if (projects[roundId] && selectedProjectId == null) {
      setSelectedProjectId(projects[roundId][0].id)
    }
  }, [projects, roundId, selectedProjectId])

  useEffect(() => {
    if (previousVals?.isSubmitting && !isSubmitting) {
      setIsModalOpen(false)
      setIsProductModalOpen(false)
    }
  }, [error, isSubmitting, previousVals?.isSubmitting])

  const dispatch = useAppDispatch()

  const fetchProjectsData = useCallback(() => {
    dispatch(fetchProjects({ roundId, teamId }))
  }, [dispatch, roundId, teamId])

  useEffect(() => {
    if (!isLoading && !error && !projects[roundId] && isProjectsEnabled) {
      fetchProjectsData()
    }
    const interval = setInterval(() => {
      dispatch(fetchProjectsBackground({ teamId, roundId }))
    }, 5000)
    return () => clearInterval(interval)
  }, [
    dispatch,
    error,
    fetchProjectsData,
    isLoading,
    projects,
    roundId,
    teamId,
    isProjectsEnabled,
  ])

  const handleOKClick = useCallback(() => {
    if (selectedProjectId) {
      const allProjects = projects[roundId]
      const selectedProject = allProjects?.find(p => p.id === selectedProjectId)

      if (
        selectedProject?.options &&
        selectedProject?.usedInRound !== roundId
      ) {
        setIsProductModalOpen(true)
      } else {
        if (selectedProject?.usedInRound === roundId) {
          dispatch(
            removeProjectDecision({
              roundId,
              teamId,
            }),
          )
        } else {
          dispatch(
            updateProjectDecision({
              roundId,
              teamId,
              projectId: selectedProjectId,
            }),
          )
        }
      }
    }
  }, [dispatch, projects, roundId, selectedProjectId, teamId])

  if (error) {
    return (
      <Page full>
        <InlineError
          message={error.message}
          onRetry={!projects[roundId] ? fetchProjectsData : null}
        />
      </Page>
    )
  }

  if (isLoading || !projects[roundId] || !selectedProjectId) {
    return <CentredSpinner />
  }

  const allProjects = [...projects[roundId]].sort((a, b) => {
    if (sortByDeliveryTime) {
      const deliveryTimeA = PROJECT_TYPE_DURATION[a.type] || 0
      const deliveryTimeB = PROJECT_TYPE_DURATION[b.type] || 0

      // Sort in descending order: longest delivery time first
      return deliveryTimeB - deliveryTimeA
    } else {
      if (!selectedImpact || !a.impacts || !b.impacts) {
        return 0
      }

      const impactA = a.impacts.find(
        impact => impact.id === selectedImpact.id,
      ) || { value: 0 }

      const impactB = b.impacts.find(
        impact => impact.id === selectedImpact.id,
      ) || { value: 0 }

      // Sort in descending order: highest impact first
      return impactB.value - impactA.value
    }
  })

  const selectedProject = allProjects?.find(p => p.id === selectedProjectId)
  const activeProjectIsTheSelectedProject =
    selectedProject?.usedInRound === roundId
  const activeProjectIsCompleted =
    selectedProject?.usedInRound != null &&
    selectedProject.usedInRound < roundId

  const handleOptionClick = (option: ProjectOption) => {
    if (selectedProjectId) {
      dispatch(
        updateProjectDecision({
          roundId,
          teamId,
          projectId: selectedProjectId,
          businessId: option.value,
        }),
      )
    }
  }

  const allImpacts =
    allProjects?.[0]?.impacts.filter(i => {
      if (demoEnabled) {
        return demoEnabledImpacts.includes(i.id)
      }
      return i.enabled
    }) ?? []

  return (
    <HorizontalGroup className="h-full w-full">
      {isProductModalOpen && (
        <Modal
          title="Select product to market"
          show={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          showFooter
        >
          <Combobox
            onChange={(item: ProjectOption) => {
              handleOptionClick(item)
            }}
          >
            {selectedProject?.options && (
              <Combobox.Options
                static
                className="flex max-h-96 w-96 flex-col gap-5 overflow-y-auto p-3"
              >
                {selectedProject.options.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'flex cursor-pointer select-none rounded-xl p-3',
                        {
                          'bg-primary-400': active,
                        },
                      )
                    }
                  >
                    {({ active }) => (
                      <div className="ml-4 flex-auto">
                        <HorizontalGroup fullWidth verticalCenter between>
                          <PrimaryText
                            colour={active ? 'white' : 'black'}
                            size="sm"
                          >
                            {option.label}
                          </PrimaryText>
                          {active && isSubmitting && <Spinner colour="white" />}
                        </HorizontalGroup>
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </Combobox>
        </Modal>
      )}
      <div
        className={classNames(
          'hidden h-screen w-auto gap-3 overflow-y-auto border-t bg-white px-4 py-3 laptop:flex laptop:flex-col',
        )}
        id="tour-projects-List"
      >
        <SortModal
          allImpacts={allImpacts}
          selectedImpact={selectedImpact}
          setSelectedImpact={setSelectedImpact}
          setSortByDeliveryTime={setSortByDeliveryTime}
        />

        {allProjects?.map((project: Project) => (
          <SpecialProjectThumbnail
            key={project.id}
            project={project}
            active={selectedProjectId === project.id}
            completed={
              project.usedInRound != null && project.usedInRound < roundId
            }
            selected={project.usedInRound === roundId}
            onClick={() => {
              setSelectedProjectId(Number(project.id))
              setAllProjectsVisible(false)
            }}
            selectedOption={project.options?.find(
              (option: ProjectOption) =>
                option.value === selectedProject?.businessId,
            )}
            selectedImpact={project.impacts.find(
              (impact: ProjectImpact) => impact.name === selectedImpact?.name,
            )}
          />
        ))}
      </div>
      <VerticalGroup className="laptop:w-[73%] monitor:w-[82%]">
        <MobilePageHeader />

        {selectedProject && (
          <ProjectDetails
            project={selectedProject}
            demoEnabled={demoEnabled}
            demoEnabledImpacts={demoEnabledImpacts}
          />
        )}
        {isModalOpen && (
          <ProjectSelectModal
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            isSubmitting={isSubmitting}
            activeProjectIsTheSelectedProject={
              activeProjectIsTheSelectedProject
            }
            handleOKClick={handleOKClick}
          />
        )}

        <ProjectSelector
          canSelect={!isSubmitting && canSelect}
          isSelected={activeProjectIsTheSelectedProject}
          isCompleted={activeProjectIsCompleted}
          onHandleSelectClick={handleSelectClick}
          onHandleViewAllProjectsClick={() => {
            setAllProjectsVisible(true)
          }}
        />

        {allProjectsVisible && (
          <VerticalGroup
            gap={4}
            className="z-1 fixed left-0 bottom-0 h-full w-full overflow-y-auto bg-white p-4 pt-24 tablet:max-h-[50vh] laptop:hidden"
          >
            <SortModal
              setSortByDeliveryTime={setSortByDeliveryTime}
              allImpacts={allImpacts}
              selectedImpact={selectedImpact}
              setSelectedImpact={setSelectedImpact}
            />
            <div className="grid grid-cols-1 gap-5 tablet:grid-cols-3">
              {allProjects?.map((project: Project) => (
                <SpecialProjectThumbnail
                  key={project.id}
                  project={project}
                  active={selectedProjectId === project.id}
                  completed={
                    project.usedInRound != null && project.usedInRound < roundId
                  }
                  selected={project.usedInRound === roundId}
                  onClick={() => {
                    setSelectedProjectId(Number(project.id))
                    setAllProjectsVisible(false)
                  }}
                  selectedOption={project.options?.find(
                    (option: ProjectOption) =>
                      option.value === selectedProject?.businessId,
                  )}
                  selectedImpact={project.impacts.find(
                    (impact: ProjectImpact) =>
                      impact.name === selectedImpact?.name,
                  )}
                />
              ))}
            </div>
          </VerticalGroup>
        )}
      </VerticalGroup>
      <SpecialProjectsTour />
    </HorizontalGroup>
  )
}

export default SpecialProjects
