import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  fetchAssessment,
  submitAnswerForQuestion,
} from '../../../redux/assessments/assessmentsActions'
import { Answer } from '../../../types/gameApi/assessments'
import Page from '../../atoms/Page/Page'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import SecondaryText from '../../atoms/Text/SecondaryText'
import { AnimatedQuestions } from './AnimatedQuestions'
import { useWindowSize } from 'react-use'
import { useIsMobile } from '../../../hooks/useIsMobile'

const Assessments = () => {
  const {
    isLoading,
    isSubmitting,
    // lastAnswerSubmitted,
    assessment,
    answers,
    isLoaded,
    roundId,
    participantId,
    error,
  } = useAppSelector(state => {
    const roundId = state.game.selectedRound
    const data = state.assessments.data[roundId]
    return {
      isLoading: state.assessments.isLoading,
      isSubmitting: state.assessments.isSubmitting,
      isSubmitted: state.assessments.isSubmitted,
      error: state.assessments.error,
      isLoaded: state.assessments.isLoaded,
      assessment: data ? data.assessment : null,
      answers: data ? data.answer : [],
      roundId,
      participantId: state.event.details?.participantId,
      // lastAnswerSubmitted: state.assessments.lastAnswerSubmitted,
    }
  })

  const [lastAnswerSubmitted, setLastAnswerSubmitted] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAssessment({ roundId }))
  }, [dispatch, roundId])

  const onSubmit = useCallback(
    async (results: Record<string, Answer[]>) => {
      if (!assessment || !participantId) return
      if (isSubmitting) return

      for (const [questionId, answer] of Object.entries(results)) {
        const body = {
          participantId,
          assessmentId: assessment.assessmentId,
          groupId: assessment.groupId,
          answer,
        }

        await dispatch(
          submitAnswerForQuestion({
            gameId: assessment.gameId,
            roundId,
            questionId,
            body,
          }),
        )
      }
    },
    [assessment, dispatch, isSubmitting, participantId, roundId],
  )

  const onSubmitLastQuestion = useCallback(() => {
    setLastAnswerSubmitted(true)
  }, [])

  const { height } = useWindowSize()
  const isMobile = useIsMobile()

  if (!isLoaded || isLoading || !assessment) {
    return <CentredSpinner />
  }

  return (
    <Page full>
      {!lastAnswerSubmitted && !error && (
        <AnimatedQuestions
          assessmentId={assessment.assessmentId}
          currentAnswers={answers}
          questions={assessment.questions}
          onSubmit={onSubmit}
          onSubmitLastQuest={onSubmitLastQuestion}
        />
      )}
      {/* TODO: decide what UI to show, and how we want to redirect the users to other pages later */}
      {error && (
        <VerticalGroup fullWidth center verticalCenter full>
          <SecondaryText weight="bold" colour="error">
            {error?.message}
          </SecondaryText>
        </VerticalGroup>
      )}
      {lastAnswerSubmitted && (
        <VerticalGroup
          className="px-4"
          fullWidth
          center
          verticalCenter
          full
          style={{ height: isMobile ? height - 20 : undefined }}
        >
          <SecondaryText weight="bold" size="3xl">
            Thank you for submitting all of your responses.
          </SecondaryText>
        </VerticalGroup>
      )}
      {isSubmitting && <div>Submitting...</div>}
    </Page>
  )
}

export default Assessments
