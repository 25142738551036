import React from 'react'

import Page from '../../../atoms/Page/Page'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import BrandedVerticalGroup from '../../../organisms/Branding/BrandedVerticalGroup/BrandedVerticalGroup'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'
import { useTranslatedText } from '../../../../hooks/useTranslatedText'

interface Props {}

const TeamWaiting: React.FC<Props> = () => {
  const bankDetailsText = useTranslatedText('team.screenHeader', 'BANK DETAILS')
  return (
    <BrandedVerticalGroup full>
      <Page className="">
        <VerticalGroup center verticalCenter className="h-[80vh]">
          <VerticalGroup gap={4}>
            <BrandedText colourField="mainTextColour" weight="bold">
              {bankDetailsText}
            </BrandedText>
            <BrandedText
              colourField="mainTextColour"
              family="secondary"
              size="sm"
            >
              As a team, please use your CEO&apos;s tablet/interface to enter:
            </BrandedText>
            <VerticalGroup gap={4} className="list-inside list-disc">
              <BrandedText
                colourField="mainTextColour"
                family="secondary"
                size="sm"
              >
                <li>Bank Name ( max 8 characters ) </li>
              </BrandedText>

              <BrandedText
                colourField="mainTextColour"
                family="secondary"
                size="sm"
              >
                <li>
                  A strategy which sets out your objectives in clear/concise
                  bullet points.{' '}
                </li>
              </BrandedText>
            </VerticalGroup>
            <BrandedText
              colourField="mainTextColour"
              family="secondary"
              size="xs"
            >
              This message will disappear once these have been completed
            </BrandedText>
          </VerticalGroup>
        </VerticalGroup>
      </Page>
    </BrandedVerticalGroup>
  )
}

export default TeamWaiting
