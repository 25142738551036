import { createSlice } from '@reduxjs/toolkit'
import APIError from '../../errors/APIError'
import { reset } from '../game/gameSlice'
import {
  AssessmentGetAnswersResponse,
  AssessmentResponse,
} from '../../types/gameApi/assessments'
import {
  fetchAssessment,
  submitAnswerForQuestion,
  submitAnswers,
} from './assessmentsActions'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

interface AssessmentState {
  isLoading: boolean
  isLoaded: boolean
  isSubmitting: boolean
  isSubmitted: boolean
  lastAnswerSubmitted: boolean

  data: {
    [roundId: number]: {
      assessment: AssessmentResponse
      answer: AssessmentGetAnswersResponse
    }
  }
  error: Error | APIError | null
  requestInProgress: string | null
}

const initialState: AssessmentState = {
  isLoading: false,
  isLoaded: false,
  isSubmitting: false,
  isSubmitted: false,
  data: {},
  error: null,
  requestInProgress: null,
  lastAnswerSubmitted: false,
}

const assessmentSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchAssessment.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.isSubmitting = false
          state.isSubmitted = false
          state.error = null
        }
      })
      .addCase(fetchAssessment.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.data[payload.roundId] = {
          assessment: payload.assessment,
          answer: payload.answers,
        }
        state.requestInProgress = null
      })
      .addCase(fetchAssessment.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(submitAnswers.pending, state => {
        state.isSubmitting = true
        state.error = null
      })
      .addCase(submitAnswers.fulfilled, state => {
        state.isSubmitting = false
        state.isSubmitted = true
        state.error = null
      })
      .addCase(submitAnswers.rejected, (state, { payload }) => {
        state.isSubmitting = false
        state.error = payload ?? null
      })

      .addCase(submitAnswerForQuestion.pending, state => {
        state.isSubmitting = true
        state.error = null
      })
      .addCase(submitAnswerForQuestion.fulfilled, (state, action) => {
        state.isSubmitting = false
        state.isSubmitted = true
        state.error = null
        const { roundId, questionId } = action.meta.arg
        const currentAssessmentData = state.data[roundId]
        if (currentAssessmentData) {
          const lastQuestionId =
            currentAssessmentData.assessment.questions[
              currentAssessmentData.assessment.questions.length - 1
            ].id
          state.lastAnswerSubmitted = questionId === lastQuestionId
        }
      })
      .addCase(submitAnswerForQuestion.rejected, (state, { payload }) => {
        state.isSubmitting = false
        state.error = payload ?? null
      })
  },
})

export default assessmentSlice.reducer
