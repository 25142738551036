import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import Page from '../../atoms/Page/Page'
import { DealroomTabs, changeTab } from '../../../redux/dealroom/dealroomSlice'
import { useAppDispatch, useAppSelector } from '../../../store'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import DropDown from '../../atoms/DropDown/DropDown'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Tabs from '../../atoms/Tabs/Tabs'
import Tab from '../../atoms/Tabs/Tab'
import { initState, reducer } from './state'
import {
  fetchDealRoom,
  fetchDealRoomBackground,
} from '../../../redux/dealroom/dealroomActions'
import { BidChangeParams, NewArgsSelectedParams } from './types'
import GameAPI from '../../../services/gameApi'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import DealRoom from './DealRoom'
import Modal from '../../atoms/Modal/Modal'
import PrimaryText from '../../atoms/Text/PrimaryText'
import TabChanger from '../../atoms/TabChanger/TabChanger'
import { useSearchParams } from 'react-router-dom'
import DealRoomTour from './DealRoomTour'
import { configs } from './configs'
const DealRoomContainer = () => {
  const { currentTab, isLoading, data, teamId, roundId, isCeo, currentRound } =
    useAppSelector(state => ({
      currentTab: state.dealroom.currentTab,
      isLoading: state.dealroom.isLoading,
      data: state.dealroom.data,
      teamId: state.event.details?.team ?? 0,
      roundId: state.game.selectedRound,
      isCeo: state.device.isCeo,
      currentRound: state.game.currentRound,
    }))
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const canBid = !!isCeo && roundId === currentRound

  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const setTab = useCallback(
    (tab: DealroomTabs) => {
      dispatch(changeTab(tab))
      setSearchParams({ tab })
    },
    [dispatch, setSearchParams],
  )
  const dealRoomData = data[roundId]

  const TABS: Array<{ label: string; value: DealroomTabs; id?: string }> =
    useMemo(() => {
      const tabs: Array<{
        label: string
        value: DealroomTabs
        enabled: boolean
        id?: string
      }> = [
        {
          label: 'OVERVIEW',
          value: 'overview',
          enabled: true,
          id: 'tour-deal-room-overview',
        },
        {
          label: 'PRICING MATRIX',
          value: 'pricing-matrix',
          enabled: dealRoomData?.type !== 'home-loan-book',
          id: 'tour-pricing-matrix',
        },
        {
          label: 'VALUATION',
          value: 'valuation',
          enabled: dealRoomData?.type !== 'home-loan-book',
          id: 'tour-valuation',
        },
      ]
      return tabs
        .filter(t => t.enabled)
        .map(({ label, value, id }) => ({ label, value, id }))
    }, [dealRoomData?.type])
  const hasTabs = TABS.length > 2
  useEffect(() => {
    const queryTab = searchParams.get('tab')
    if (queryTab && TABS.some(tab => tab.value === queryTab)) {
      dispatch(changeTab(queryTab as DealroomTabs))
    }
  }, [TABS, dispatch, searchParams])

  const [state, localDispatch] = useReducer(reducer, initState)

  useEffect(() => {
    dispatch(fetchDealRoom({ teamId, roundId }))
    if (!isCeo) {
      const interval = setInterval(() => {
        dispatch(fetchDealRoomBackground({ teamId, roundId }))
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [roundId, dispatch, teamId, isCeo])

  useEffect(() => {
    // Update default Args
    if (isLoading || !data[roundId]) {
      return
    }
    const dealroom = data[roundId]
    localDispatch({ type: 'UpdateBid', payload: dealroom.bid })
    localDispatch({
      type: 'UpdateArgs',
      payload: { argx: dealroom.argx, argy: dealroom.argy },
    })
    localDispatch({ type: 'UpdateBidEnabled', payload: !!dealroom.enabled })
    localDispatch({
      type: 'UpdateAssumptionOverrides',
      payload: dealroom.assumptionOverrides ?? [],
    })
  }, [data, isLoading, roundId])

  const onBidChange = useCallback(
    async ({
      bid,
      argx,
      argy,
      enabled,
      assumptionOverrides,
    }: BidChangeParams) => {
      if (bid != null) {
        localDispatch({
          type: 'UpdateBid',
          payload: bid,
        })
      }
      if (argx && argy) {
        localDispatch({
          type: 'UpdateArgs',
          payload: { argx, argy },
        })
      }

      if (enabled != null) {
        localDispatch({
          type: 'UpdateBidEnabled',
          payload: enabled,
        })
      }
      await GameAPI.bidOnDeal({
        teamId,
        roundId,
        bid: bid ?? state.bid ?? 0,
        argx: argx ?? state.argx,
        argy: argy ?? state.argy,
        enabled: enabled ?? state.bidEnabled,
        assumptionOverrides: (
          assumptionOverrides ??
          state.assumptionOverrides ??
          []
        ).map(assumption => {
          return !isNaN(assumption) && assumption != null ? assumption : -1
        }),
      })
    },
    [
      teamId,
      roundId,
      state.bid,
      state.argx,
      state.argy,
      state.bidEnabled,
      state.assumptionOverrides,
    ],
  )

  const onNewArgsSelected = useCallback(
    ({ bid, argx, argy }: NewArgsSelectedParams) => {
      onBidChange({
        bid,
        argx,
        argy,
        enabled: true,
      })
    },
    [onBidChange],
  )

  const onAssumptionUpdate = useCallback(
    (index: number, value: number) => {
      localDispatch({
        type: 'UpdateAssumptionOverride',
        payload: { index, value },
      })
      const overridesCopy = [...state.assumptionOverrides]
      overridesCopy[index] = value
      onBidChange({
        assumptionOverrides: overridesCopy,
      })
    },
    [onBidChange, state],
  )

  const onResetAssumptions = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleResetConfirm = () => {
    localDispatch({ type: 'UpdateAssumptionOverrides', payload: [] })
    onBidChange({
      assumptionOverrides: [],
    })
    setShowModal(false)
  }

  if (isLoading || !data[roundId]) {
    return <CentredSpinner />
  }
  return (
    <Page noPadding full>
      {TABS.length > 1 && (
        <TabChanger
          currentTab={currentTab as string}
          tabs={TABS.map(t => t.value as string)}
          setTab={tab => setTab(tab as DealroomTabs)}
        />
      )}
      <VerticalGroup gap={4}>
        <MobilePageHeader className="px-4">
          {TABS.length > 1 && (
            <DropDown<DealroomTabs>
              paddingLeft
              items={TABS}
              selectedItem={currentTab}
              onSelectedItemChange={item => {
                setTab(item.value)
              }}
            />
          )}
        </MobilePageHeader>
        <HorizontalGroup
          className="hidden px-4 tablet:flex tablet:pt-2"
          fullWidth
        >
          {TABS.length > 1 && (
            <Tabs>
              {TABS.map(t => (
                <Tab
                  key={t.value as string}
                  tab={t}
                  onClick={() => setTab(t.value)}
                  active={currentTab === t.value}
                  id={t.id}
                />
              ))}
            </Tabs>
          )}
        </HorizontalGroup>

        <DealRoom
          canBid={canBid}
          currentTab={currentTab}
          dealroom={data[roundId]}
          state={state}
          onBidChange={onBidChange}
          onNewArgsSelected={onNewArgsSelected}
          onAssumptionUpdate={onAssumptionUpdate}
          onResetAssumptions={onResetAssumptions}
        />
        <div className="h-20 desktop:hidden"></div>
      </VerticalGroup>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title="Confirm Reset"
        onOk={handleResetConfirm}
        onCancel={handleCloseModal}
      >
        <PrimaryText>Are you sure you want to reset assumptions?</PrimaryText>
      </Modal>
      <DealRoomTour
        config={configs[data[roundId].type]}
        setTab={setTab}
        hasTabs={hasTabs}
      />
    </Page>
  )
}

export default DealRoomContainer
