import React, { useCallback, useMemo } from 'react'
import InAppHelpContent from '../../organisms/InAppHelp/InAppHelpContent'
import Text from '../../atoms/Text/Text'
import GuidedTour, {
  GuidedTourStep,
} from '../../organisms/InAppHelp/GuidedTour'
import { useAppDispatch, useAppSelector } from '../../../store'
import { finishTour } from '../../../redux/guidedTours/guidedToursSlice'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import { TreasuryTabs } from '../../../redux/treasury/treasurySlice'

interface TreasuryTourProps {
  setTab: (tab: TreasuryTabs) => void
  data: any
}

const TreasuryTour: React.FC<TreasuryTourProps> = ({ setTab, data }) => {
  const dispatch = useAppDispatch()
  const currentTour = useAppSelector(state => state.guidedTours.currentTour)
  const ProfileVsAssetsTabChange = useCallback(() => {
    setTab('wsf-profile')
  }, [setTab])
  const RiskWeightedAssetsTabChange = useCallback(() => {
    setTab('risk-weighted-assets')
  }, [setTab])
  const CapitalManagementTabChange = useCallback(() => {
    setTab('capital-management')
  }, [setTab])
  const { filteredSteps } = useMemo(() => {
    const stepsArray: Array<Partial<GuidedTourStep>> = [
      {
        target: '#tour-forecast',
        content: (
          <InAppHelpContent heading="Forecast Assets & funding">
            <Text left>
              {
                'A simplified version of your forecast balance sheet is shown here using these stacked bars. Total Funding should always exceed Total Assets.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },

      {
        target: '#tour-asset-growth',
        content: (
          <InAppHelpContent heading={'Asset Growth'}>
            <Text left>
              {
                'Your estimated asset growth this year. This is the weighted average of the asset growth rates currently entered in your Businesses screen. It is more accurate to update asset growth forecasts for each product using the Businesses interface rather than this total asset growth number.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-deposits-growth',
        content: (
          <InAppHelpContent heading="Deposit Growth">
            <Text left>
              {
                'Your estimated deposit growth this year. The same as the value entered in your Businesses screen.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-wsf',
        content: (
          <InAppHelpContent heading="New Wholesale Funding">
            <Text left>
              {
                'This is your decision of the amount of new wholesale funding to raise this year. Raise enough funding so that Total Funding exceeds Total Assets.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-capital-ratio',
        content: (
          <InAppHelpContent heading="Estimated Capital Ratio">
            <Text left>
              {
                'This shows your projected capital ratio for the year, based on your current balance sheet and forecasted changes. The capital ratio is calculated by dividing your total capital by your risk-weighted assets'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-cash',
        content: (
          <InAppHelpContent heading={'Cash'}>
            <Text left>
              {
                'Ensure you have enough cash to meet liquidity requirements. It is recommended that you maintain a cash balance equal to or above 10% of deposits.'
              }
            </Text>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-wsf-profile',
        content: (
          <InAppHelpContent heading={'Wholesale Funding Profile'}>
            <Text left>
              {
                'On this interface you can adjust the term of the wholesale funding being raised. You can raise bonds with a life of between 1-5 years.'
              }
            </Text>
          </InAppHelpContent>
        ),
        onClick: ProfileVsAssetsTabChange,
      },
      {
        target: '#tour-last-wsf-year-input',
        content: (
          <InAppHelpContent heading={'Default Wholesale Funding Term'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'All wholesale funding is raised as 5 years bonds by default. This amount is the same as the amount on the Treasury Balance Sheet tab.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-wsf-third-year-input',
        content: (
          <InAppHelpContent heading={'Changing Funding Terms'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'Select each of the input boxes to raise funding with each duration. You can raise a combination of 1,2,3,4 and 5 year funding.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-risk-weighted-assets',
        content: (
          <InAppHelpContent heading={'Risk Weighted Assets & Deductions'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'On this screen you can make adjustments for expected changes in risk-weighted assets.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
        onClick: RiskWeightedAssetsTabChange,
      },
      {
        target: '#tour-growth-rates-input',
        content: (
          <InAppHelpContent heading={'Asset Growth Rates'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'These are the forecast asset growth rates currently entered in your Businesses screen. You can also adjust them here.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-risk-weight-input',
        content: (
          <InAppHelpContent heading={'Risk Weights'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'These are the forecast risk-weightings of your products. If you have undertaken Special Projects this year which specifically impact risk-weights, you should reflect these impacts for each product.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-capital-management',
        content: (
          <InAppHelpContent heading={'Capital Management'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'This screen is used to raise capital from shareholders to meet your regulatory capital obligations.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
        onClick: CapitalManagementTabChange,
      },
      {
        target: '#tour-capital-ratio-input',
        content: (
          <InAppHelpContent heading={'Target Capital Ratio'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'Set your overall target capital ratio that you are aiming to achieve.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-dividends-reinvested-input',
        content: (
          <InAppHelpContent heading={'Dividends Reinvested'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  "Set how much of this year's Dividends you will ask shareholders to reinvest. This will generate Share Capital more efficiently that a Share Issue."
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-group-npat',
        content: (
          <InAppHelpContent heading={'Estimated Net Profit After Tax'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  "Enter this year's estimated NPAT. This is used to estimate how much Share Capital will be raised from the Dividend Reinvestment Plan this year."
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
      {
        target: '#tour-additional-share-issues',
        content: (
          <InAppHelpContent heading={'Additional Share Issues'}>
            <VerticalGroup gap={0}>
              <Text left>
                {
                  'The remaining Share Capital change needed to achieve your Target Capital Ratio is shown here.'
                }
              </Text>
            </VerticalGroup>
          </InAppHelpContent>
        ),
      },
    ]
    const wsfRelatedTargets = [
      '#tour-wsf-profile',
      '#tour-last-wsf-year-input',
      '#tour-wsf-third-year-input',
    ]

    const rwaRelatedTargets = [
      '#tour-risk-weighted-assets',
      '#tour-growth-rates-input',
      '#tour-risk-weight-input',
    ]

    const capitalRelatedTargets = [
      '#tour-capital-management',
      '#tour-capital-ratio-input',
      '#tour-dividends-reinvested-input',
      '#tour-group-npat',
      '#tour-additional-share-issues',
    ]

    const filtered = stepsArray.filter(step => {
      if (typeof step.target === 'string') {
        if (wsfRelatedTargets.includes(step.target) && data.wsfMethod !== 1)
          return false
        if (rwaRelatedTargets.includes(step.target) && data.capitalMethod !== 1)
          return false
        if (
          capitalRelatedTargets.includes(step.target) &&
          data.capitalMethod !== 1
        )
          return false
        if (step.target === '#tour-capital-ratio' && data.capitalMethod !== 1)
          return false
        if (step.target === '#tour-wsf' && data.wsfMethod === 3) return false
      }
      return true
    })

    return { steps: stepsArray, filteredSteps: filtered }
  }, [
    ProfileVsAssetsTabChange,
    RiskWeightedAssetsTabChange,
    CapitalManagementTabChange,
    data.capitalMethod,
    data.wsfMethod,
  ])

  const handleCloseTour = useCallback(() => {
    dispatch(finishTour())
  }, [dispatch])

  if (currentTour !== 'treasury') {
    return null
  }

  return (
    <GuidedTour
      disableScrolling
      steps={filteredSteps}
      onClose={handleCloseTour}
    />
  )
}

export default TreasuryTour
