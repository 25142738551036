import React from 'react'

import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Button from '../../atoms/Button/Button'
import classNames from 'classnames'
import PrimaryText from '../../atoms/Text/PrimaryText'

interface ProjectSelectorProps {
  canSelect: boolean
  isSelected: boolean
  isCompleted: boolean
  onHandleSelectClick: () => void
  onHandleViewAllProjectsClick: () => void
  className?: string
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  canSelect,
  isSelected,
  isCompleted,
  onHandleSelectClick,
  onHandleViewAllProjectsClick,
  className,
}) => {
  return (
    <>
      <div className={classNames('h-28', className)}></div>
      <HorizontalGroup
        gap={2}
        fullWidth
        verticalCenter
        className="fixed bottom-0 flex h-20 justify-around bg-white px-2 laptop:bg-gray-100"
      >
        {canSelect && (
          <Button
            id="tour-select-project"
            disabled={isCompleted}
            className="block flex-grow laptop:flex-grow-0"
            rounded="full"
            size="large"
            buttonType={
              isCompleted ? 'orange' : isSelected ? 'error' : 'primary'
            }
            onClick={onHandleSelectClick}
          >
            <PrimaryText weight="bold" size="lg">
              {isCompleted ? 'COMPLETED' : isSelected ? 'UNSELECT' : 'SELECT'}
            </PrimaryText>
          </Button>
        )}

        <PrimaryText size="sm">
          <Button
            className="laptop:hidden"
            rounded="full"
            buttonType="secondary"
            size="large"
            text="View All Projects"
            onClick={onHandleViewAllProjectsClick}
          ></Button>
        </PrimaryText>
      </HorizontalGroup>
    </>
  )
}

export default ProjectSelector
