import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { changeTab } from '../../../../redux/peer-insights/peerInsightsSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
interface Props {}
const PeerInsightsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const translations = useMenuTranslations()

  const handlePeerInsightsTour = useCallback(() => {
    dispatch(startTour('peer-insights'))
    dispatch(changeTab('market-share'))
    navigate('/peer-insights')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.peerInsights} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handlePeerInsightsTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'Market Share & Pricing Summary'}
            content={
              "This screen provides an overview of key financial metrics for each bank, including market share, interest rates, support costs, and profitability. Use this data to compare your bank's performance against competitors and make strategic decisions to become the top bank."
            }
            hint={
              'Analyze the metrics to identify areas where your bank can improve, such as increasing market share, optimizing interest rates, or reducing costs. Look for opportunities to differentiate your offerings and attract more customers.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'Summary Metrics'}
            content={
              "This dashboard compares your bank's key performance indicators (KPIs) to competitors, including financial, customer, employee, and risk metrics. Quickly identify your strengths and weaknesses in the market."
            }
            hint={
              'Focus on improving under performing areas, especially customer-centric KPIs. Balance shareholder returns with effective risk management for long-term success.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default PeerInsightsHelp
