import { createAsyncThunk } from '@reduxjs/toolkit'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { RootState } from '../../store'
import {
  AssessmentRequest,
  AssessmentResponse,
  CurrentAnswers,
  SubmitAnswerForQuestionRequest,
  SubmitAnswerForQuestionResponse,
  SubmitAnswersRequest,
  SubmitAnswersResponse,
} from '../../types/gameApi/assessments'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export const fetchAssessment = createAsyncThunk<
  { roundId: number; assessment: AssessmentResponse; answers: CurrentAnswers },
  AssessmentRequest,
  { rejectValue: Error | APIError; state: RootState }
>(
  'assessments/fetch',
  async ({ roundId }, { rejectWithValue, getState, requestId }) => {
    const state = getState()
    try {
      if (state.assessments.requestInProgress !== requestId) {
        throw new ThunkCancelledError()
      }
      const assessment = await gameAPI.getAssessment({ roundId })
      const answers = await gameAPI.getCurrentAnwers({ roundId })

      return { roundId, assessment, answers }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const submitAnswers = createAsyncThunk<
  { roundId: number; response: SubmitAnswersResponse },
  SubmitAnswersRequest,
  { rejectValue: Error | APIError; state: RootState }
>('assessments/submit', async ({ roundId, body }, { rejectWithValue }) => {
  try {
    const response = await gameAPI.submitAnswers({ roundId, body })

    return { roundId, response }
  } catch (error: any) {
    return rejectWithValue(error)
  }
})

export const submitAnswerForQuestion = createAsyncThunk<
  {
    gameId: string
    roundId: number
    questionId: string
    response: SubmitAnswerForQuestionResponse
  },
  SubmitAnswerForQuestionRequest,
  { rejectValue: Error | APIError; state: RootState }
>(
  'assessments/submitAnswerForQuestion',
  async ({ gameId, roundId, questionId, body }, { rejectWithValue }) => {
    try {
      const response = await gameAPI.submitAnswerForQuestion({
        gameId,
        roundId,
        questionId,
        body,
      })

      return { gameId, roundId, questionId, response }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
