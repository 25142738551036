import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { changeTab } from '../../../../redux/financials/financialsSlice'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'
interface Props {}
const OurFinancialsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const translations = useMenuTranslations()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleOurFinancialsTour = useCallback(() => {
    dispatch(startTour('our-financials'))
    dispatch(changeTab('summary'))
    navigate('/our-financials')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.ourFinancials} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleOurFinancialsTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'SUMMARY'}
            content={
              'This screen displays key financial metrics for your bank and their changes.'
            }
            hint={
              "Use this overview to quickly assess your bank's financial health. Pay attention to trends in profitability, growth, and risk metrics. If any metric is significantly under performing, dig deeper in the other sections to identify the root causes and develop a plan to address them."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'PROFIT & LOSS'}
            content={
              "This screen displays your bank's profit and loss for the previous year/round and the return on equity for each product."
            }
            hint={
              'Analyze the profitability of each product line to identify areas for improvement. Consider factors such as pricing, volumes, and costs. Look for opportunities to cross-sell products to boost overall profitability.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'PROFIT & LOSS DIVISION'}
            content={
              'This screen displays the profit and loss for each product for the previous year/round.'
            }
            hint={
              'Compare the performance of each division to identify strengths and weaknesses. Consider reallocating resources to high-performing divisions or investing in initiatives to turn around under performing areas.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
          <SubheadingDropDown
            title={'BALANCE SHEET'}
            content={
              'This screen shows the Balance Sheet for your entire bank at the end of the previous year/round and how Regulatory Capital (Shareholders’ Funds) is allocated.'
            }
            hint={
              'Monitor your balance sheet health closely, particularly your capital adequacy ratio. Ensure you have sufficient capital allocated to each product line to support growth while managing risk.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default OurFinancialsHelp
