import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { IconType } from '../components/atoms/Icon/Icon'
import { translate } from './useTranslatedText'
import { useAppSelector } from '../store'
import { RoundSetupBase } from '../types/gameApi/device'
import { FeatureKey } from '../components/organisms/Sidebar/NewFeatureAlert'

export interface RouteWithMeta {
  name: string
  href: string
  iconType: IconType
  current: boolean
  type: 'information' | 'decision'
  translateKey: string
  orderKey: string
  enabled: boolean
  feature?: FeatureKey
  id:
    | 'summary'
    | 'breaking-news'
    | 'peer-insights'
    | 'our-financials'
    | 'help-manual'
    | 'costs'
    | 'special-projects'
    | 'deal-room'
    | 'treasury'
    | 'exco-decisions'
    | 'businesses'
    | 'assessments'
}

interface RouteWithMetaAndOrder extends RouteWithMeta {
  order: number
}

const sortByOrder = (a: RouteWithMetaAndOrder, b: RouteWithMetaAndOrder) =>
  a.order - b.order

export const useRoutes = (): {
  routes: RouteWithMeta[]
  current?: RouteWithMeta
} => {
  const location = useLocation()
  const {
    content,
    currentRoundSetup,
    currentRound,
    selectedRound,
    roundsSetup,
    isWrapUp,
  } = useAppSelector(state => ({
    content: state.content,
    currentRoundSetup: state.game.currentRoundSetup,
    roundsSetup: state.game.roundsSetup,
    currentRound: state.game.currentRound,
    selectedRound: state.game.selectedRound,
    isWrapUp: state.game.state === 'wrapup',
  }))

  let roundSetup: RoundSetupBase = currentRoundSetup
  if (selectedRound !== currentRound) {
    const selectedRoundSetup = roundsSetup.find(
      rs => rs.round === selectedRound,
    )
    if (selectedRoundSetup != null) {
      roundSetup = selectedRoundSetup
    }
  }

  const {
    insightsEnabled,
    surveyEnabled,
    dealsEnabled,
    excoEnabled,
    specialProjectsEnabled,
    operatingCostsEnabled,
  } = roundSetup

  return useMemo(() => {
    const routes: RouteWithMeta[] = [
      {
        name: 'Summary',
        href: 'summary',
        iconType: 'summary',
        current: false,
        type: 'information',
        translateKey: 'mainMenu.summaryButton',
        orderKey: 'mainMenuOrder.summaryButton',
        enabled: true,
        id: 'summary',
      },
      {
        name: 'Breaking News',
        href: 'breaking-news',
        iconType: 'news',
        current: false,
        type: 'information',
        translateKey: 'mainMenu.newsButton',
        orderKey: 'mainMenuOrder.newsButton',
        enabled: !isWrapUp,
        id: 'breaking-news',
      },
      {
        name: 'Peer Insights',
        href: 'peer-insights',
        iconType: 'peer',
        current: false,
        type: 'information',
        translateKey: 'mainMenu.peerInsightsButton',
        orderKey: 'mainMenuOrder.peerInsightsButton',
        enabled: insightsEnabled,
        feature: 'insightsEnabled',
        id: 'peer-insights',
      },
      {
        name: 'Our Financials',
        href: 'our-financials',
        iconType: 'financials',
        current: false,
        type: 'information',
        translateKey: 'mainMenu.ourFinancialsButton',
        orderKey: 'mainMenuOrder.ourFinancialsButton',
        enabled: true,
        id: 'our-financials',
      },
      {
        name: 'Help Manual',
        href: 'help-manual',
        iconType: 'info',
        current: false,
        type: 'information',
        translateKey: 'mainMenu.helpManualButton',
        orderKey: 'mainMenuOrder.helpManualButton',
        enabled: true,
        id: 'help-manual',
      },
      {
        name: 'Opex',
        href: 'costs',
        iconType: 'cost',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.costsButton',
        orderKey: 'mainMenuOrder.costsButton',
        enabled: !isWrapUp && operatingCostsEnabled,
        feature: 'operatingCostsEnabled',
        id: 'costs',
      },
      {
        name: 'Special Projects',
        href: 'special-projects',
        iconType: 'projects',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.specialProjectsButton',
        orderKey: 'mainMenuOrder.specialProjectsButton',
        enabled: !isWrapUp && specialProjectsEnabled,
        feature: 'specialProjectsEnabled',
        id: 'special-projects',
      },
      {
        name: 'Deal Room',
        href: 'deal-room',
        iconType: 'deal',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.dealRoomButton',
        orderKey: 'mainMenuOrder.dealRoomButton',
        enabled: !isWrapUp && dealsEnabled,
        feature: 'dealsEnabled',
        id: 'deal-room',
      },
      {
        name: 'Treasury',
        href: 'treasury',
        iconType: 'treasury',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.treasuryButton',
        orderKey: 'mainMenuOrder.treasuryButton',
        enabled: !isWrapUp,
        id: 'treasury',
      },
      {
        name: 'Exco Decisions',
        href: 'exco-decisions',
        iconType: 'exco',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.excoButton',
        orderKey: 'mainMenuOrder.excoButton',
        enabled: !isWrapUp && excoEnabled,
        feature: 'excoEnabled',
        id: 'exco-decisions',
      },
      {
        name: 'Businesses',
        href: 'businesses',
        iconType: 'businesses',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.businessesButton',
        orderKey: 'mainMenuOrder.businessesButton',
        enabled: !isWrapUp,
        id: 'businesses',
      },
      {
        name: 'Assessment',
        href: 'assessments',
        iconType: 'exco',
        current: false,
        type: 'decision',
        translateKey: 'mainMenu.assessmentButton',
        orderKey: 'mainMenuOrder.assessmentButton',
        enabled: !isWrapUp && surveyEnabled,
        feature: 'surveyEnabled',
        id: 'assessments',
      },
    ]
    const routesWithCurrent: RouteWithMetaAndOrder[] = routes.map(
      (item, i) => ({
        ...item,
        current: location.pathname.includes(item.href),
        name: translate(item.translateKey, content, item.name),
        order: parseInt(translate(item.orderKey, content, String(i))),
      }),
    )
    const sorted: RouteWithMetaAndOrder[] = routesWithCurrent
      .filter(r => r.enabled)
      .sort(sortByOrder)
    const currentRoute = sorted.find(r => r.current)
    return { routes: sorted, current: currentRoute }
  }, [
    isWrapUp,
    insightsEnabled,
    operatingCostsEnabled,
    specialProjectsEnabled,
    dealsEnabled,
    excoEnabled,
    surveyEnabled,
    location.pathname,
    content,
  ])
}
