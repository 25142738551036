import React, { useCallback, useState } from 'react'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import Text from '../../../atoms/Text/Text'
import { Icon } from '../../../atoms/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import SubheadingDropDown from '../Components/SubheadingDropDown'
import { useMenuTranslations } from '../../../../hooks/useMenuTranslations'
import Button from '../../../atoms/Button/Button'

interface Props {}

const CostsHelp: React.FC<Props> = () => {
  const [expandedSubheading, setExpandedSubheading] = useState('')
  const translations = useMenuTranslations()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleCostsHelpTour = useCallback(() => {
    dispatch(startTour('costs'))
    navigate('/costs')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        {translations.costs} Guide
      </Text>{' '}
      <VerticalGroup gap={6}>
        <HorizontalGroup center>
          <Button
            buttonType="secondary"
            full
            size="xl"
            onClick={handleCostsHelpTour}
          >
            <Icon type="guide" colour="primary" size={4} />
            <Text colour="primary">Start Walkthrough</Text>
          </Button>
        </HorizontalGroup>
        <VerticalGroup gap={6}>
          <SubheadingDropDown
            title={'OPERATING DIVISIONS TILES'}
            content={
              "These four divisions can be used to adjust operational resourcing and impact your bank's key summary metrics. Also, staff salaries can be adjusted in the last tile."
            }
            hint={
              "Experiment with different resource allocations to find the optimal balance for your bank's strategy. Keep an eye on the Metric Impacts tile to see how your changes affect key performance indicators."
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'METRIC IMPACTS'}
            content={
              'This tile shows the expected change in your important metrics as a result of your current decisions. It also shows the forecast of each metric value at the end of the current year. Note that while these include the impact of projects selected in previous years, they do not include the project selected in the current year.'
            }
            hint={
              'Use this information to guide your resourcing decisions. If a metric is under performing, consider allocating more resources to the relevant division.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />

          <SubheadingDropDown
            title={'TOTAL IMPACT'}
            content={
              'This shows you the total additional operating costs and people you will require this year as a result of your decisions. These amounts are incremental to the maintenance levels your bank would expect in a normal year of operations.'
            }
            hint={
              'Keep an eye on these total impacts to ensure your decisions are sustainable for your bank. If the additional costs or headcount are too high, you may need to adjust your resource allocation or project selection.'
            }
            expandedSubheading={expandedSubheading}
            setExpandedSubheading={setExpandedSubheading}
          />
        </VerticalGroup>
      </VerticalGroup>
    </VerticalGroup>
  )
}

export default CostsHelp
