import React from 'react'
import classNames from 'classnames'
import { ReactComponent as LoanIcon } from '../../../assets/summary/loan.svg'
import { ReactComponent as CreditIcon } from '../../../assets/summary/credit.svg'
import { ReactComponent as CustomersIcon } from '../../../assets/summary/customers.svg'
import { ReactComponent as DangersIcon } from '../../../assets/summary/dangers.svg'
import { ReactComponent as DislikeIcon } from '../../../assets/summary/dislike.svg'
import { ReactComponent as GavelIcon } from '../../../assets/summary/gavel.svg'
import { ReactComponent as LeafIcon } from '../../../assets/summary/leaf.svg'
import { ReactComponent as NpsIcon } from '../../../assets/summary/nps.svg'
import { ReactComponent as RatingIcon } from '../../../assets/summary/rating.svg'
import { ReactComponent as RoeIcon } from '../../../assets/summary/roe.svg'
import { ReactComponent as SummaryIcon } from '../../../assets/sidebar/summary.svg'
import { ReactComponent as TrophyIcon } from '../../../assets/summary/trophy.svg'

import { ReactComponent as NewsImage } from '../../../assets/sidebar/news.svg'
import { ReactComponent as BusinessesIcon } from '../../../assets/sidebar/businesses.svg'
import { ReactComponent as CostsIcon } from '../../../assets/sidebar/costs.svg'
import { ReactComponent as DealRoomIcon } from '../../../assets/sidebar/deal.svg'
import { ReactComponent as ExcoIcon } from '../../../assets/sidebar/exco.svg'
import { ReactComponent as FinancialsIcon } from '../../../assets/sidebar/financials.svg'
import { ReactComponent as infoIcon } from '../../../assets/common/info.svg'
import { ReactComponent as broadcast } from '../../../assets/common/broadcast.svg'
import { ReactComponent as ProjectsIcon } from '../../../assets/sidebar/special.svg'
import { ReactComponent as TreasuryIcon } from '../../../assets/sidebar/treasury.svg'
import { ReactComponent as signOffIcon } from '../../../assets/sidebar/signOff.svg'
import { ReactComponent as submitIcon } from '../../../assets/sidebar/submit.svg'
import { ReactComponent as CapitalRatioIcon } from '../../../assets/financials/capitalRatio.svg'
import { ReactComponent as CostOfFundsIcon } from '../../../assets/financials/costOfFunds.svg'
import { ReactComponent as CET1 } from '../../../assets/financials/costToIncome.svg'
import { ReactComponent as NimIcon } from '../../../assets/financials/nim.svg'
import { ReactComponent as NpatGrowthIcon } from '../../../assets/financials/npatGrowth.svg'
import { ReactComponent as RemediationAndFinesIcon } from '../../../assets/financials/remediationAndFines.svg'
import { ReactComponent as LoanImpairmentIcon } from '../../../assets/financials/loanImpairment.svg'
import { ReactComponent as ComplaintsIcon } from '../../../assets/financials/complaints.svg'
import { ReactComponent as EmploymentEngagementIcon } from '../../../assets/financials/employmentEngagement.svg'
import { ReactComponent as TotalShareIcon } from '../../../assets/financials/totalShareHolder.svg'
import { ReactComponent as MedalIcon } from '../../../assets/summary/medal.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/common/changeUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/common/changeDown.svg'
import { ReactComponent as RoeFinIcon } from '../../../assets/financials/roeFin.svg'
import { ReactComponent as ClockIcon } from '../../../assets/header/clock.svg'
import { ReactComponent as MortgagesIcon } from '../../../assets/financials/mortgages.svg'
import { ReactComponent as BusinessLendingIcon } from '../../../assets/financials/businessLending.svg'
import { ReactComponent as DepositsIcon } from '../../../assets/financials/deposits.svg'
import { ReactComponent as CreditCardsIcon } from '../../../assets/financials/creditCards.svg'
import { ReactComponent as InstitutionalBankingIcon } from '../../../assets/financials/institutionalBanking.svg'
import { ReactComponent as OffshoreBankingIcon } from '../../../assets/financials/offshoreBank.svg'
import { ReactComponent as WealthManagementIcon } from '../../../assets/financials/wealthManagement.svg'

import { ReactComponent as ChangesIcon } from '../../../assets/financials/changes.svg'
import { ReactComponent as DollarIcon } from '../../../assets/financials/dollarCapital.svg'
import { ReactComponent as PeerIcon } from '../../../assets/sidebar/Peer.svg'

import { ReactComponent as BearIcon } from '../../../assets/breakingNews/bear.svg'
import { ReactComponent as BullIcon } from '../../../assets/breakingNews/bull.svg'

import { ReactComponent as ChevronIcon } from '../../../assets/common/chevronDown.svg'
import { ReactComponent as ExcludeIcon } from '../../../assets/projects/exclude.svg'
import { ReactComponent as PositiveIcon } from '../../../assets/projects/positive.svg'
import { ReactComponent as SortingIcon } from '../../../assets/projects/sorting.svg'
import { ReactComponent as ChevronUpIcon } from '../../../assets/common/chevronUp.svg'
import { ReactComponent as RotateIcon } from '../../../assets/common/rotate.svg'
import { ReactComponent as MinusIcon } from '../../../assets/common/minus.svg'
import { ReactComponent as PlusIcon } from '../../../assets/common/plus.svg'
import { ReactComponent as RefreshIcon } from '../../../assets/common/refresh.svg'
import { ReactComponent as ResetIcon } from '../../../assets/common/reset.svg'
import { ReactComponent as ChevronRightIcon } from '../../../assets/common/chevronRight.svg'
import { ReactComponent as ChevronLeftIcon } from '../../../assets/common/chevronLeft.svg'
import { ReactComponent as UpTickIcon } from '../../../assets/common/tick.svg'
import { ReactComponent as SettingIcon } from '../../../assets/common/setting.svg'
import { ReactComponent as WarningIcon } from '../../../assets/common/warning.svg'
import { ReactComponent as HelpIcon } from '../../../assets/common/help.svg'
import { ReactComponent as CloseIcon } from '../../../assets/common/close.svg'
import { ReactComponent as GuideIcon } from '../../../assets/common/guide.svg'
import { ReactComponent as BackIcon } from '../../../assets/common/back.svg'

import { ReactComponent as AssetIcon } from '../../../assets/treasury/assetGrowth.svg'
import { ReactComponent as DepoIcon } from '../../../assets/treasury/depositsGrowth.svg'
import { ReactComponent as WsfIcon } from '../../../assets/treasury/wSF.svg'
import { ReactComponent as PencilIcon } from '../../../assets/treasury/pencil.svg'
import { ReactComponent as DividendsReinvestedIcon } from '../../../assets/treasury/dividendsReinvested.svg'
import { ReactComponent as CreditRatingIcon } from '../../../assets/treasury/rating.svg'
import { ReactComponent as ArrowRightIcon } from '../../../assets/treasury/arrowRight.svg'

import { ReactComponent as IdeaIcon } from '../../../assets/waitingRoom/idea.svg'
import { ReactComponent as BankIcon } from '../../../assets/waitingRoom/bank.svg'
import { ReactComponent as HourglassIcon } from '../../../assets/waitingRoom/hourglass.svg'
import { ReactComponent as CheckingIcon } from '../../../assets/waitingRoom/checking.svg'
import { ReactComponent as LockedIcon } from '../../../assets/waitingRoom/locked.svg'
import { ReactComponent as NotStartedIcon } from '../../../assets/waitingRoom/notStarted.svg'
import { ReactComponent as ReadyIcon } from '../../../assets/waitingRoom/ready.svg'
import { ReactComponent as WillResumeIcon } from '../../../assets/waitingRoom/willResume.svg'
import { ReactComponent as CEOIcon } from '../../../assets/waitingRoom/ceo.svg'

import { ReactComponent as FrontlineIcon } from '../../../assets/costs/frontline.svg'
import { ReactComponent as HRIcon } from '../../../assets/costs/hr.svg'
import { ReactComponent as ITIcon } from '../../../assets/costs/it.svg'
import { ReactComponent as RiskIcon } from '../../../assets/costs/risk.svg'
import { ReactComponent as SalaryIcon } from '../../../assets/costs/salary.svg'
import { ReactComponent as CommunityTrustIcon } from '../../../assets/costs/communityTrust.svg'
import { ReactComponent as EngagementIcon } from '../../../assets/costs/engagement.svg'
import { ReactComponent as NegativeIcon } from '../../../assets/costs/negative.svg'
import { ReactComponent as SmileIcon } from '../../../assets/costs/positive.svg'
import { ReactComponent as PeopleImpactIcon } from '../../../assets/costs/peopleImpact.svg'
import { ReactComponent as CostImpactIcon } from '../../../assets/costs/costImpact.svg'
import { ReactComponent as LockIcon } from '../../../assets/costs/lock.svg'
import { ReactComponent as unlockIcon } from '../../../assets/costs/unlock.svg'

import { Colour, Spacing } from '../../../types/theme'

const mapTypeToIcon = {
  loan: LoanIcon,
  rating: RatingIcon,
  roe: RoeIcon,
  dislike: DislikeIcon,
  nps: NpsIcon,
  gavel: GavelIcon,
  leaf: LeafIcon,
  danger: DangersIcon,
  customers: CustomersIcon,
  credit: CreditIcon,
  summary: SummaryIcon,
  trophy: TrophyIcon,

  news: NewsImage,
  peer: PeerIcon,
  businesses: BusinessesIcon,
  cost: CostsIcon,
  deal: DealRoomIcon,
  exco: ExcoIcon,
  financials: FinancialsIcon,
  info: infoIcon,
  broadcast,
  projects: ProjectsIcon,
  treasury: TreasuryIcon,
  power: signOffIcon,
  submit: submitIcon,
  capital: CapitalRatioIcon,
  funds: CostOfFundsIcon,
  cet1: CET1,
  nim: NimIcon,
  npat: NpatGrowthIcon,
  remediation: RemediationAndFinesIcon,
  lie: LoanImpairmentIcon,
  complaints: ComplaintsIcon,
  employment: EmploymentEngagementIcon,
  share: TotalShareIcon,
  medal: MedalIcon,
  up: ArrowUpIcon,
  down: ArrowDownIcon,
  roefin: RoeFinIcon,
  clock: ClockIcon,
  mortgages: MortgagesIcon,
  lending: BusinessLendingIcon,
  deposits: DepositsIcon,
  card: CreditCardsIcon,
  institutionalBanking: InstitutionalBankingIcon,
  offshoreBanking: OffshoreBankingIcon,
  wealthManagement: WealthManagementIcon,
  changes: ChangesIcon,
  dollar: DollarIcon,

  bear: BearIcon,
  bull: BullIcon,

  chevron: ChevronIcon,

  exclude: ExcludeIcon,
  positive: PositiveIcon,
  sorting: SortingIcon,

  chevronUp: ChevronUpIcon,
  rotate: RotateIcon,
  minus: MinusIcon,
  plus: PlusIcon,
  refresh: RefreshIcon,
  reset: ResetIcon,
  asset: AssetIcon,
  depo: DepoIcon,
  wsf: WsfIcon,
  pencil: PencilIcon,
  dividendsReinvested: DividendsReinvestedIcon,
  creditrating: CreditRatingIcon,

  chevronRight: ChevronRightIcon,
  chevronLeft: ChevronLeftIcon,

  uptick: UpTickIcon,
  setting: SettingIcon,

  idea: IdeaIcon,
  bank: BankIcon,
  hourglass: HourglassIcon,

  checking: CheckingIcon,
  locked: LockedIcon,
  notStarted: NotStartedIcon,
  ready: ReadyIcon,
  willResume: WillResumeIcon,
  ceo: CEOIcon,
  arrowRight: ArrowRightIcon,

  frontline: FrontlineIcon,
  hr: HRIcon,
  it: ITIcon,
  risk: RiskIcon,
  salary: SalaryIcon,
  engagement: EngagementIcon,
  communityTrust: CommunityTrustIcon,
  negative: NegativeIcon,
  smile: SmileIcon,
  people: PeopleImpactIcon,
  costs: CostImpactIcon,
  lock: LockIcon,
  unlock: unlockIcon,
  warning: WarningIcon,
  help: HelpIcon,
  close: CloseIcon,
  guide: GuideIcon,
  back: BackIcon,
}

export type IconType = keyof typeof mapTypeToIcon

export interface Props {
  type: IconType
  className?: string
  size?: Spacing
  colour?: Colour
  // strokeColour?: Theme.Colours
  style?: React.CSSProperties
  disabled?: boolean
  responsiveSize?: string
  id?: string
  idPrefix?: string
}

export const FILL_COLOUR_CLASSES: Record<Colour, string> = {
  success: 'fill-success stroke-success',
  error: 'fill-error stroke-error',
  primary: 'fill-primary stroke-primary',
  orange: 'fill-orange stroke-orange',
  pink: 'fill-pink stroke-pink',
  green: 'fill-green stroke-green',
  purple: 'fill-purple stroke-purple',
  darkblue: 'fill-darkblue stroke-darkblue',
  gray: 'fill-gray stroke-gray',
  white: 'fill-white stroke-white',
  black: 'fill-black stroke-black',
  lightgray: 'fill-lightgray stroke-lightgray',
}

export const Icon = ({
  type,
  className,
  colour,
  style,
  size,
  disabled,
  responsiveSize,
  id,
  idPrefix,
}: Props) => {
  const SVGIcon = mapTypeToIcon[type]
  const fillColour = colour ? FILL_COLOUR_CLASSES[colour] : ''
  const sizeClass = size
    ? `w-${size} h-${size}`
    : responsiveSize
    ? responsiveSize
    : ''
  return (
    <SVGIcon
      id={idPrefix ? `${idPrefix}-icon` : id}
      className={classNames(fillColour, sizeClass, className, {
        'fill-gray-300 stroke-gray-300': disabled,
      })}
      style={style}
    />
  )
}
