import React, { useMemo } from 'react'

import { RootState } from '../../../../store'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'

import Card from '../../../atoms/Card/Card'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import { Icon } from '../../../atoms/Icon/Icon'

import CentredSpinner from '../../../molecules/CentredSpinner/CentredSpinner'
import openNewTab from '../../../../lib/openNewTab'
import BrandedButton from '../../../organisms/Branding/BrandedButton/BrandedButton'
import BrandedIcon from '../../../organisms/Branding/BrandedIcon/BrandedIcon'
import BrandedText from '../../../organisms/Branding/BrandedText/BrandedText'
import SecondaryText from '../../../atoms/Text/SecondaryText'

interface Props {
  game: RootState['game']
  device: RootState['device']
}

const WaitingRoomSimulationResultsCard: React.FC<Props> = ({
  game,
  device,
}) => {
  const deviceStatusChecked = useMemo(
    () =>
      device && device.registered
        ? device.deviceStatusChecked
        : !!device?.error,
    [device],
  )

  const rounds = React.useMemo(() => {
    if (!game?.totalRounds) {
      return null
    }
    const results = []
    for (let i = 1; i <= game.totalRounds; i++) {
      const publishedResults = game?.results?.find(r => r.roundId === i)
      results.push({
        round: i,
        ready: !!publishedResults,
        url: publishedResults ? publishedResults.url : null,
      })
    }
    return results
  }, [game?.totalRounds, game?.results])

  return (
    <VerticalGroup fullWidth>
      <Card className="p-4 tablet:p-8">
        <VerticalGroup gap={2} fullWidth>
          <BrandedText colourField="mainTextColour" weight="bold" size="lg">
            Simulation Results
          </BrandedText>
          {deviceStatusChecked && rounds ? (
            rounds.map(r => (
              <HorizontalGroup between key={r.round} fullWidth>
                <HorizontalGroup
                  verticalCenter
                  fullWidth
                  between
                  className=""
                  data-test={`result-row-${r.round}`}
                >
                  <HorizontalGroup verticalCenter className="h-10">
                    <BrandedText
                      colourField="mainTextColour"
                      family="secondary"
                      size="sm"
                    >
                      Round {r.round}
                    </BrandedText>
                  </HorizontalGroup>
                  <HorizontalGroup className="h-10" verticalCenter>
                    {r.ready && (
                      <>
                        <Icon size={6} type="uptick" colour="success" />
                        <BrandedText
                          colourField="mainTextColour"
                          family="secondary"
                          paddingRight={2}
                          size="sm"
                        >
                          Ready To View
                        </BrandedText>
                      </>
                    )}
                    {!r.ready && (
                      <SecondaryText colour="gray" size="sm">
                        Not Completed
                      </SecondaryText>
                    )}

                    {r.ready && (
                      <BrandedButton
                        size="xs"
                        rounded="full"
                        onClick={() => {
                          if (r.ready && r.url) {
                            openNewTab(`${r.url}`)
                          }
                        }}
                      >
                        <BrandedIcon
                          colourField="buttonTextColour"
                          size={6}
                          type="chevronRight"
                          colour="white"
                        />
                      </BrandedButton>
                    )}
                  </HorizontalGroup>
                </HorizontalGroup>
              </HorizontalGroup>
            ))
          ) : (
            <CentredSpinner />
          )}
        </VerticalGroup>
      </Card>
    </VerticalGroup>
  )
}

export default WaitingRoomSimulationResultsCard
