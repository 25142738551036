import React, { useCallback, useState } from 'react'
import Card from '../../atoms/Card/Card'
import { Icon } from '../../atoms/Icon/Icon'
import Clickable from '../../atoms/Clickable/Clickable'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Image from '../../atoms/Image/Image'
import logoText from '../../../assets/excelerate-logo-white.png'
import Text from '../../atoms/Text/Text'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import { useAppSelector } from '../../../store'
import SelectedItemContent from './Components/SelectedItemContent'
import HelpContentList from './Components/HelpContentList'

interface Props {
  handleHelpClick: () => void
}

const HelpCenter: React.FC<Props> = ({ handleHelpClick }) => {
  const { event, maskParticipants } = useAppSelector(state => ({
    event: state.event.details,
    maskParticipants: state.game.maskParticipants,
  }))
  const [selectedItem, setSelectedItem] = useState('')

  const handleClose = useCallback(() => {
    handleHelpClick()
  }, [handleHelpClick])

  const handleBackClick = useCallback(() => {
    setSelectedItem('')
  }, [])

  const renderContent = () => {
    if (selectedItem) {
      return <SelectedItemContent selectedItem={selectedItem} />
    } else {
      return <HelpContentList setSelectedItem={setSelectedItem} />
    }
  }

  return (
    <Card className="w-full pb-4 desktop:max-w-sm">
      <div className="rounded-tl-lg rounded-tr-lg bg-gradient-to-l from-primary to-primary-300 desktop:h-1/4">
        <VerticalGroup full className="px-7 pt-4 pb-10">
          <HorizontalGroup between fullWidth>
            {selectedItem ? (
              <Clickable onClick={handleBackClick}>
                <HorizontalGroup verticalCenter gap={1}>
                  <Icon colour="white" size={4} type="back" />
                  <Text size="sm" colour="white">
                    Back
                  </Text>
                </HorizontalGroup>
              </Clickable>
            ) : (
              <Image className="w-28" src={logoText} />
            )}
            <Clickable onClick={handleClose}>
              <Icon colour="white" size={4} type="close" />
            </Clickable>
          </HorizontalGroup>
          <VerticalGroup verticalCenter full>
            <Text size="2xl" colour="white">
              Hi {maskParticipants ? '' : event?.firstName ?? 'Participant'}!
            </Text>
            <Text colour="white">Welcome to the Help Center.</Text>
          </VerticalGroup>
        </VerticalGroup>
      </div>
      <div className="z-10 -mt-10 flex h-5/6 justify-center px-3 pb-4">
        <Card className="w-full overflow-y-auto overscroll-contain">
          <VerticalGroup className="h-full p-2 text-center">
            {renderContent()}
          </VerticalGroup>
        </Card>
      </div>
    </Card>
  )
}

export default HelpCenter
