import React, { useCallback, useState } from 'react'

import Toggle from '../../../atoms/Toggle/Toggle'
import HorizontalGroup from '../../../atoms/HorizontalGroup/HorizontalGroup'
import SecondaryText from '../../../atoms/Text/SecondaryText'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import NumericInput from '../../../atoms/Input/NumericInput'
import ErrorModal from '../../../atoms/Modal/ErrorModal'

interface Props {
  onValueChange?: (value: number, enabled: boolean) => void
  value: number | undefined
  enabled: boolean
  canBid: boolean
}

const DealRoomBidInput: React.FC<Props> = ({
  onValueChange,
  value,
  enabled,
  canBid,
}) => {
  const [error, setError] = useState<string | null>()
  const toggleBidStatus = useCallback(() => {
    onValueChange && onValueChange(value ?? 0, !enabled)
  }, [enabled, onValueChange, value])

  return (
    <VerticalGroup gap={2}>
      {error && (
        <ErrorModal
          showModal={!!error}
          heading="Invalid Input"
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <VerticalGroup gap={1}>
        {canBid && (
          <SecondaryText weight="bold">
            <Toggle
              id="tour-deal-bid"
              label="Bid"
              enabled={enabled}
              toggleEnabled={toggleBidStatus}
            />
          </SecondaryText>
        )}

        {canBid && (
          <div className="h-full">
            <SecondaryText size="lg" center weight="bold">
              {enabled ? '' : 'No Bid'}
            </SecondaryText>
          </div>
        )}
        {!canBid && !enabled && (
          <div className="h-full">
            <SecondaryText size="base" center weight="bold">
              Bids submitted by CEO only
            </SecondaryText>
          </div>
        )}
      </VerticalGroup>
      {enabled && (
        <HorizontalGroup fullWidth verticalCenter gap={2}>
          <div className="w-1/3">
            <NumericInput
              allowNegative={false}
              type="integer"
              paddingY={3}
              size="lg"
              onError={setError}
              validateInput={(val: number) => {
                if (val < 0) {
                  return 'Bid must be greater than 0'
                }
              }}
              disabled={!canBid}
              value={value ?? 0}
              onChange={(value: number) => {
                if (value >= 0) {
                  onValueChange && onValueChange(value, true)
                }
              }}
            />
          </div>

          <SecondaryText>m</SecondaryText>
        </HorizontalGroup>
      )}
    </VerticalGroup>
  )
}

export default DealRoomBidInput
