import React, { useCallback, useEffect, useRef, useState } from 'react'
import Sidebar from '../../organisms/Sidebar/Sidebar'
import Header from '../../organisms/Header/Header'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import HeartBeater from '../../../services/heartbeater'
import { useAppSelector } from '../../../store'
import WaitingRoom from '../WaitingRoom/WaitingRoomContainer'
import Team from '../Team/Team'
import WarningAlert from '../../organisms/WarningAlert/WarningAlert'
import BroadcastAlert from '../../organisms/BroadcastAlert/BroadcastAlert'
import WaitingRoomCeoTransferAlert from '../WaitingRoom/WaitingRoomCeoTransfer/WaitingRoomCeoTransferAlert'
import ScrollToTop from '../../atoms/ScrollToTop/ScrollToTop'
import Image from '../../atoms/Image/Image'
import DemoSettings from '../DemoSettings/DemoSettings'
import HelpCenter from '../../organisms/HelpCenter/HelpCenter'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import { registerPageView } from '../../../lib/googleTagManager'
import { useEventStatus } from '../../../hooks/useEventStatus'
import RoundOneInitialTour from './RoundOneInitialTour'
import RoundTwoInitialTour from './RoundTwoInitialTour'
interface Props {}
const Layout: React.FC<Props> = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [showHelp, setShowHelp] = useState(false)
  const {
    teamId,
    roundId,
    isTablet,
    helpFiles,
    dismissedInAppHelp,
    eventId,
    round,
  } = useAppSelector(state => ({
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
    isTablet: state.auth.isTablet,
    helpFiles: state.help.files,
    dismissedInAppHelp: state.inAppHelp.dismissedInAppHelp,
    eventId: state.event.details?.eventId,
    round: state.game.currentRound,
  }))

  const eventStatus = useEventStatus()
  useEffect(() => {
    registerPageView(
      eventStatus.enableButton,
      location.pathname,
      searchParams.get('tab') ?? undefined,
      searchParams.get('subtab') ?? undefined,
      searchParams.get('advanced') === 'true',
    )
    setSidebarOpen(false)
    setShowHelp(false)
  }, [
    eventStatus.enableButton,
    location,
    searchParams,
    dismissedInAppHelp,
    eventId,
    round,
  ])

  const heartbeater = useRef<HeartBeater>()
  useEffect(() => {
    if (!heartbeater.current?.working) {
      heartbeater.current = new HeartBeater()
      //only called once after first render
      heartbeater.current.start()
    }
  }, [])

  const handleHelpClick = useCallback(() => {
    setShowHelp(prevShowHelp => !prevShowHelp)
    setSidebarOpen(false)
  }, [])

  return (
    <>
      {isTablet && helpFiles.map(f => <Image src={f} key={f} hidden />)}
      <WarningAlert />
      <BroadcastAlert />
      <WaitingRoomCeoTransferAlert />
      <WaitingRoom>
        {location.pathname === '/demo-settings' ? (
          <DemoSettings />
        ) : location.pathname === '/team' ? (
          <Team />
        ) : (
          <div className="relative flex tablet:h-screen">
            <Sidebar
              open={sidebarOpen}
              toggleOpen={setSidebarOpen}
              onHelpClick={handleHelpClick}
            />
            <VerticalGroup className="flex-1 desktop:pl-64" fullWidth>
              <Header toggleSidebar={setSidebarOpen} />
              <main className="flex-1 overflow-y-auto">
                {teamId > 0 && roundId > 0 && <Outlet />}
                <ScrollToTop />
              </main>
            </VerticalGroup>
            {showHelp && (
              <HorizontalGroup className="fixed top-0 left-0 bottom-0 z-50 w-full desktop:left-64 desktop:top-auto desktop:h-5/6 desktop:w-fit">
                {showHelp && <HelpCenter handleHelpClick={handleHelpClick} />}
              </HorizontalGroup>
            )}
            {process.env.REACT_APP_ENABLED_HELP_CENTER === 'true' && (
              <>
                <RoundOneInitialTour />
                <RoundTwoInitialTour />
              </>
            )}
          </div>
        )}
      </WaitingRoom>
    </>
  )
}

export default Layout
