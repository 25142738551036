import { LoginParams } from '../../../types/adminApi/auth'
import { getEventsForParticipantData } from './mocks/event'
import { getLoginDetails } from './mocks/login'
import { getParticipantDetailsData } from './mocks/participant'

export const HOST = process.env.REACT_APP_ADMIN_API_URL?.includes('localhost')
  ? process.env.REACT_APP_ADMIN_API_URL.replace(
      'localhost',
      process.env.REACT_APP_LOCAL_IP ?? 'localhost',
    )
  : process.env.REACT_APP_ADMIN_API_URL

class AdminAPI {
  token: string | null
  eventId: string | null

  constructor() {
    this.token = null
    this.eventId = null
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  })

  setToken = (token: string | null) => {
    this.token = token
  }

  setEventId = (eventId: string | null) => {
    this.eventId = eventId
  }

  login = async (params: LoginParams) => {
    return { data: getLoginDetails(params) }
  }

  getEventsForParticipant = async () => {
    return getEventsForParticipantData()
  }

  getParticipantDetails = async () => {
    return getParticipantDetailsData()
  }

  getResources = async () => {
    return []
  }

  getParticipants = async () => {
    return []
  }

  getAssessmentPreview = async () => {
    return null
  }
  getScenarioPreview = async () => {
    return null
  }
  ping = async () => {
    return { data: { ok: true } }
  }
  sendConnectivityResults = async () => {
    return { data: { success: true } }
  }
}

const api = new AdminAPI()

export default api
