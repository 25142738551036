import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import CentredSpinner from '../../molecules/CentredSpinner/CentredSpinner'
import Tabs from '../../atoms/Tabs/Tabs'
import {
  changeMetric,
  changeTab,
  PeerInsightsMetricTabs,
  PeerInsightsTabs,
} from '../../../redux/peer-insights/peerInsightsSlice'
import VerticalGroup from '../../atoms/VerticalGroup/VerticalGroup'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import Page from '../../atoms/Page/Page'
import { fetchPeerInsights } from '../../../redux/peer-insights/peerInsightsActions'
import Tab from '../../atoms/Tabs/Tab'
import MarketShare from './MarketShare/MarketShare'
import MobilePageHeader from '../../organisms/MobilePageHeader/MobilePageHeader'
import SummaryMetrics from './SummaryMetrics/SummaryMetrics'
import { fetchSummary } from '../../../redux/summary/summaryActions'
import DropDown, { MenuItem } from '../../atoms/DropDown/DropDown'
import PrimaryText from '../../atoms/Text/PrimaryText'
import { getIconForSummaryMetric, getMetricColours } from '../../../lib/icons'
import { Icon } from '../../atoms/Icon/Icon'
import InlineError from '../../atoms/InlineError/InlineError'
import TabChanger from '../../atoms/TabChanger/TabChanger'

import PeerInsightsTour from './PeerInsightsTour/PeerInsightsTour'
import { useIsMobile } from '../../../hooks/useIsMobile'

import { useSearchParams } from 'react-router-dom'
import InAppHelp from '../../organisms/InAppHelp/InAppHelp'
import Text from '../../atoms/Text/Text'
import { finishInAppHelp } from '../../../redux/inAppHelp/inAppHelpSlice'
interface Props {}

const TABS: Array<{ label: string; value: PeerInsightsTabs; id?: string }> = [
  {
    label: 'MARKET SHARE & PRICING',
    value: 'market-share',
    id: 'tour-market-share-pricing',
  },
  { label: 'SUMMARY METRICS', value: 'metrics', id: 'tour-summary-metrics' },
]

type MobileTabs = 'market-share' | PeerInsightsMetricTabs

const PeerInsights: React.FC<Props> = () => {
  const {
    currentTab,
    currentMetric,
    summaries,
    error,
    isLoading,
    peerInsights,
    teamId,
    roundId,
    demoEnabled,
    demoEnabledSummaryMetrics,
    eventId,
    currentTour,
    hasVisitedMetricsForThisEvent,
  } = useAppSelector(state => ({
    currentTab: state.peerInsights.currentTab,
    currentMetric: state.peerInsights.currentMetric,
    isLoading: state.peerInsights.isLoading,
    error: state.peerInsights.error,
    peerInsights: state.peerInsights.insights,
    summaries: state.summary.summaries,
    teamId: state.event.details?.team ?? 0,
    roundId: state.game.selectedRound,
    demoEnabled: state.demo.enabled,
    demoEnabledSummaryMetrics: state.demo.enabledSummaryMetrics,
    eventId: state.event.details?.eventId,
    currentTour: state.guidedTours.currentTour,
    hasVisitedMetricsForThisEvent: state.event.details?.eventId
      ? state.inAppHelp.dismissedInAppHelp[
          `${state.event.details.eventId}-summary-metrics-reminder`
        ]
      : false,
  }))
  const dispatch = useAppDispatch()

  const isMobile = useIsMobile()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showInAppHelp, setShowInAppHelp] = useState(false)
  const [inAppHelpMode, setInAppHelpMode] = useState<'beacon' | 'floater'>(
    'beacon',
  )

  const fetchPeerInsightsData = useCallback(() => {
    dispatch(fetchPeerInsights({ roundId, teamId }))
  }, [dispatch, roundId, teamId])

  useEffect(() => {
    if (!isLoading && !error && !peerInsights[roundId]) {
      fetchPeerInsightsData()
    }
    if (
      teamId > 0 &&
      roundId > 0 &&
      (summaries[roundId] == null || summaries[roundId].length === 0)
    ) {
      dispatch(fetchSummary({ roundId, teamId }))
    }
  }, [
    dispatch,
    roundId,
    summaries,
    teamId,
    error,
    isLoading,
    fetchPeerInsightsData,
    peerInsights,
  ])
  useEffect(() => {
    if (hasVisitedMetricsForThisEvent || currentTour === 'peer-insights') {
      return
    }
    const thirtySecondTimer = setTimeout(() => {
      setShowInAppHelp(true)
    }, 30000)

    const sixtySecondTimer = setTimeout(() => {
      setInAppHelpMode('floater')
    }, 60000)

    return () => {
      clearTimeout(thirtySecondTimer)
      clearTimeout(sixtySecondTimer)
    }
  }, [hasVisitedMetricsForThisEvent, eventId, currentTour])
  useEffect(() => {
    const queryTab = searchParams.get('tab')
    if (queryTab && TABS.some(tab => tab.value === queryTab)) {
      dispatch(changeTab(queryTab as PeerInsightsTabs))
    }
  }, [searchParams, dispatch])

  const setTab = useCallback(
    (tab: PeerInsightsTabs) => {
      dispatch(changeTab(tab))
      setSearchParams({
        tab,
        subtab: tab === 'metrics' ? currentMetric ?? '' : '',
      })
      if (
        tab === 'metrics' &&
        !hasVisitedMetricsForThisEvent &&
        eventId &&
        currentTour !== 'peer-insights'
      ) {
        dispatch(finishInAppHelp(`${eventId}-summary-metrics-reminder`))
      }
    },
    [
      dispatch,
      setSearchParams,
      currentMetric,
      hasVisitedMetricsForThisEvent,
      eventId,
      currentTour,
    ],
  )

  const setMetric = useCallback(
    (metric: PeerInsightsMetricTabs) => {
      dispatch(changeMetric(metric))
      setSearchParams({ tab: currentTab, subtab: metric })
    },
    [currentTab, dispatch, setSearchParams],
  )

  const handleCloseMetrics = useCallback(() => {
    dispatch(changeTab('market-share'))
    setSearchParams({ tab: 'market-share' })
  }, [dispatch, setSearchParams])

  if (error) {
    return (
      <Page full>
        <InlineError message={error.message} onRetry={fetchPeerInsightsData} />
      </Page>
    )
  }

  if (isLoading || !peerInsights[roundId] || !summaries[roundId]) {
    return <CentredSpinner />
  }

  const data = peerInsights[roundId]
  const summaryData = summaries[roundId]

  const mobileTabs: MenuItem<MobileTabs>[] = [
    {
      label: 'MARKET SHARE & PRICING',
      itemRow: (
        <HorizontalGroup gap={2} verticalCenter>
          <Icon type="cet1" size={4} colour="primary" />
          <PrimaryText size="sm">MARKET SHARE & PRICING</PrimaryText>
        </HorizontalGroup>
      ),
      value: 'market-share',
    },
  ]

  for (let i = 0; i < data.data.length; i++) {
    const metric = data.data[i]
    const label =
      summaryData.find(s => s.type === metric.type)?.heading.toUpperCase() ??
      metric.type

    const isEnabled = demoEnabled
      ? demoEnabledSummaryMetrics.includes(metric.type)
      : !!summaryData.find(s => s.type === metric.type)?.enabled
    if (isEnabled) {
      mobileTabs.push({
        label,
        itemRow: (
          <HorizontalGroup gap={2} verticalCenter>
            <Icon
              type={getIconForSummaryMetric(metric.type)}
              size={4}
              colour={getMetricColours(i)}
            />
            <PrimaryText size="sm">{label}</PrimaryText>
          </HorizontalGroup>
        ),
        value: metric.type,
      })
    }
  }

  const tabs = TABS.map(t => t.value)

  const steps = [
    {
      target: '#tour-summary-metrics',
      placement: 'right' as const,
      content: (
        <>
          <Text left weight="bold">
            {'Summary Metrics'}
          </Text>
          <Text left>
            {
              'Select this button to see more detail on each of the summary metrics.'
            }
          </Text>
        </>
      ),
    },
  ]

  return (
    <Page full>
      <TabChanger currentTab={currentTab} tabs={tabs} setTab={setTab} />
      <VerticalGroup full gap={4}>
        <MobilePageHeader>
          {currentMetric && (
            <div>
              <DropDown<MobileTabs>
                menuItemsClassname="w-64"
                items={mobileTabs}
                selectedItem={
                  currentTab === 'market-share' ? currentTab : currentMetric
                }
                onSelectedItemChange={item => {
                  if (item.value === 'market-share') {
                    setTab(item.value)
                  } else {
                    setTab('metrics')
                    setMetric(item.value)
                  }
                }}
              />
            </div>
          )}
        </MobilePageHeader>
        <HorizontalGroup className="hidden w-fit tablet:block">
          <Tabs>
            {TABS.map(t => (
              <Tab
                key={t.value as string}
                tab={t}
                onClick={() => setTab(t.value)}
                active={currentTab === t.value}
                data-active={currentTab === t.value}
                data-test={t.value}
                id={t.id}
              />
            ))}
          </Tabs>
        </HorizontalGroup>
        <div className="h-full">
          {currentTab === 'market-share' && (
            <>
              <MarketShare marketShare={data.marketShare} teamId={teamId} />
            </>
          )}
          {currentTab === 'metrics' && (
            <>
              <SummaryMetrics
                data={data.data}
                teamId={teamId}
                summaries={summaryData}
                roundId={roundId}
                handleCloseMetrics={handleCloseMetrics}
                setMetric={setMetric}
              />
            </>
          )}
        </div>
      </VerticalGroup>

      {!isMobile && !!data && (
        <PeerInsightsTour
          setTab={setTab}
          setMetric={setMetric}
          metrics={data.data}
        />
      )}
      {!isMobile && showInAppHelp && (
        <InAppHelp
          mode={inAppHelpMode}
          steps={steps}
          storageKey={'summary-metrics-reminder'}
        />
      )}
    </Page>
  )
}

export default PeerInsights
