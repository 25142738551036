import React, { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Icon } from '../../atoms/Icon/Icon'
import SidebarContent from './SideBarContent'
import PrimaryText from '../../atoms/Text/PrimaryText'
import { useAppDispatch, useAppSelector } from '../../../store'
import Logo from '../../atoms/Logo/Logo'
import Clickable from '../../atoms/Clickable/Clickable'
import { useNavigate } from 'react-router-dom'
import Modal from '../../atoms/Modal/Modal'
import { submitRound } from '../../../redux/game/gameActions'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'

interface Props {
  open: boolean
  toggleOpen: (value: boolean) => void
  onHelpClick: () => void
}

const Sidebar: React.FC<Props> = ({ open, toggleOpen, onHelpClick }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showSignOffAlert, setShowSignOffAlert] = useState(false)

  const { teamId, teamName, roundId, isCeo } = useAppSelector(state => ({
    teamId: state.team.id ?? 0,
    roundId: state.game.selectedRound,
    teamName: state.team.name,
    isCeo: state.device.isCeo,
  }))

  const formattedTeamName = `Team ${teamId}: ${teamName?.toUpperCase()} BANK`

  const handleLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleSignOffClick = useCallback(() => {
    setShowSignOffAlert(true)
  }, [])

  const onSubmitCancel = useCallback(() => {
    setShowSignOffAlert(false)
  }, [])

  const handleSubmit = useCallback(() => {
    if (teamId > 0 && roundId > 0) {
      dispatch(submitRound({ teamId, roundId }))
      setShowSignOffAlert(false)
      navigate('/')
    }
  }, [dispatch, navigate, roundId, teamId])

  return (
    <>
      <Modal
        show={showSignOffAlert}
        onOk={handleSubmit}
        onClose={onSubmitCancel}
        onCancel={onSubmitCancel}
        title="Confirm sign off"
      >
        <div className="pt-4">
          <p>Are you sure you want to sign off on your decisions?</p>
        </div>
      </Modal>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 desktop:hidden"
          onClose={toggleOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex max-w-xs flex-1 flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => toggleOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-gray-900 py-2 px-6 pb-4 ring-1 ring-white/10">
                  {/* <div className="flex h-16 shrink-0 items-center"> */}
                  <Clickable onClick={handleLogoClick}>
                    <Logo />
                  </Clickable>

                  <PrimaryText
                    colour="white"
                    weight="semibold"
                    paddingY={2}
                    notResponsive
                  >
                    {formattedTeamName}
                  </PrimaryText>
                  <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
                    <nav className="flex flex-1 flex-col space-y-1">
                      <SidebarContent />
                    </nav>
                    {isCeo && (
                      <div className="flex">
                        <HorizontalGroup between fullWidth>
                          {isCeo && (
                            <div className="flex">
                              <button
                                className="flex items-center gap-2"
                                onClick={handleSignOffClick}
                              >
                                <Icon size={7} type="submit" colour="white" />
                                <PrimaryText
                                  size="sm"
                                  colour="white"
                                  notResponsive
                                >
                                  SIGN OFF
                                </PrimaryText>
                              </button>
                            </div>
                          )}
                          {process.env.REACT_APP_ENABLED_HELP_CENTER ===
                            'true' && (
                            <Clickable
                              className="flex items-center"
                              onClick={onHelpClick}
                            >
                              <Icon size={8} type="help" colour="white" />
                            </Clickable>
                          )}
                        </HorizontalGroup>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden desktop:fixed desktop:inset-y-0 desktop:flex desktop:w-64 desktop:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-gray-800 p-6">
          <Clickable onClick={handleLogoClick}>
            <Logo />
          </Clickable>

          <PrimaryText
            colour="white"
            weight="semibold"
            paddingY={2}
            notResponsive
          >
            {formattedTeamName}
          </PrimaryText>
          <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
            <nav className="flex flex-1 flex-col space-y-1">
              <SidebarContent />
            </nav>
            <HorizontalGroup between fullWidth>
              {isCeo && (
                <div className="flex">
                  <button
                    className="flex items-center gap-2"
                    onClick={handleSignOffClick}
                  >
                    <Icon size={7} type="submit" colour="white" />
                    <PrimaryText size="sm" colour="white" notResponsive>
                      SIGN OFF
                    </PrimaryText>
                  </button>
                </div>
              )}
              {process.env.REACT_APP_ENABLED_HELP_CENTER === 'true' && (
                <Clickable className="flex items-center" onClick={onHelpClick}>
                  <Icon size={8} type="help" colour="white" />
                </Clickable>
              )}
            </HorizontalGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
