import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  fetchBusinesses,
  fetchBusinessesBackground,
  updateBusinessDecisions,
} from './businessActions'
import APIError from '../../errors/APIError'
import { reset } from '../game/gameSlice'
import { BusinessResponse, ProductType } from '../../types/gameApi/business'
import { logout } from '../auth/authSlice'
import ThunkCancelledError from '../../errors/ThunkCancelledError'

export type BusinessTabs = 'decisions' | 'price-volume-trade-off'

interface BusinessesState {
  currentTab: BusinessTabs
  isLoading: boolean
  isLoaded: boolean
  isLoadingBackground: boolean
  showAdvanced: boolean
  currentBusiness: ProductType | null
  data: {
    [roundId: number]: BusinessResponse
  }
  error: Error | APIError | null
  requestInProgress: string | null
}

const initialState: BusinessesState = {
  currentTab: 'decisions',
  isLoading: false,
  isLoaded: false,
  isLoadingBackground: false,
  showAdvanced: false,
  currentBusiness: null,
  data: {},
  error: null,
  requestInProgress: null,
}

const businessesSLice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<BusinessTabs>) => {
      state.currentTab = action.payload
    },
    changeBusiness: (state, action: PayloadAction<ProductType>) => {
      state.currentBusiness = action.payload
    },
    toggleShowAdvanced: (state, action: PayloadAction<boolean>) => {
      state.showAdvanced = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(reset, () => {
        return initialState
      })
      .addCase(logout, () => {
        return initialState
      })
      .addCase(fetchBusinesses.pending, (state, { meta }) => {
        if (!state.isLoading) {
          state.requestInProgress = meta.requestId
          state.isLoading = true
          state.error = null
        }
      })
      .addCase(fetchBusinesses.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = null
        state.data[payload.roundId] = payload.business
        if (!state.currentBusiness) {
          state.currentBusiness = payload.business.businesses[0].type
        }
        state.requestInProgress = null
      })
      .addCase(fetchBusinesses.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoading = false
        state.error = payload ?? null
        state.requestInProgress = null
      })
      .addCase(fetchBusinessesBackground.pending, (state, { meta }) => {
        if (!state.isLoading && !state.isLoadingBackground) {
          state.requestInProgress = meta.requestId
          state.isLoadingBackground = true
        }
      })
      .addCase(fetchBusinessesBackground.fulfilled, (state, { payload }) => {
        state.isLoadingBackground = false
        state.data[payload.roundId] = payload.business
        state.requestInProgress = null
      })
      .addCase(fetchBusinessesBackground.rejected, (state, { payload }) => {
        if (payload instanceof ThunkCancelledError) {
          return
        }
        state.isLoadingBackground = false
        state.requestInProgress = null
      })
      .addCase(updateBusinessDecisions.fulfilled, (state, { payload }) => {
        state.data[payload.roundId].businesses = state.data[
          payload.roundId
        ].businesses.map(b => {
          const businessDecisions = payload.decisions.filter(
            d => d.businessId === b.id,
          )
          let update = { ...b, version: new Date().valueOf() }
          if (businessDecisions.length) {
            for (const bd of businessDecisions) {
              update = {
                ...update,
                price1Now: bd.price1Now ?? update.price1Now,
                price2Now: bd.price2Now ?? update.price2Now,
              }
            }
          }
          return update
        })
      })
  },
})

export const { changeTab, changeBusiness, toggleShowAdvanced } =
  businessesSLice.actions

export default businessesSLice.reducer
