import React, { useCallback } from 'react'
import { Icon } from '../../../atoms/Icon/Icon'
import Clickable from '../../../atoms/Clickable/Clickable'
import Text from '../../../atoms/Text/Text'
import VerticalGroup from '../../../atoms/VerticalGroup/VerticalGroup'
import { useRoutes } from '../../../../hooks/useRoutes'
import { startTour } from '../../../../redux/guidedTours/guidedToursSlice'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Button from '../../../atoms/Button/Button'

interface Props {
  setSelectedItem: (item: string) => void
}

const HelpContentList: React.FC<Props> = ({ setSelectedItem }) => {
  const { routes } = useRoutes()
  const informationNav = routes.filter(r => r.type === 'information')
  const decisionNav = routes.filter(r => r.type === 'decision')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentRound } = useAppSelector(state => ({
    currentRound: state.game.currentRound,
  }))
  const handleItemClick = useCallback(
    (item: string) => {
      setSelectedItem(item)
    },
    [setSelectedItem],
  )
  const handleRoundOneInitialTour = useCallback(() => {
    dispatch(startTour('round-one-initial'))
    navigate('/summary')
  }, [dispatch, navigate])
  const handleRoundTwoInitialTour = useCallback(() => {
    dispatch(startTour('round-two-initial'))
    navigate('/summary')
  }, [dispatch, navigate])

  return (
    <VerticalGroup gap={2}>
      <Text center size="lg">
        Select the area you need help with below.
      </Text>
      {currentRound === 1 ? (
        <Button
          buttonType="secondary"
          full
          size="xl"
          onClick={handleRoundOneInitialTour}
        >
          <Text colour="primary">Start Round 1 Walkthrough</Text>
          <Icon type="guide" colour="primary" responsiveSize="xs" size={4} />
        </Button>
      ) : (
        <Button
          buttonType="secondary"
          full
          size="xl"
          onClick={handleRoundTwoInitialTour}
        >
          <Icon type="guide" responsiveSize="xs" size={4} colour="primary" />
          <Text colour="primary">Start Round 2 Walkthrough</Text>
        </Button>
      )}

      <Text weight="semibold" size="lg">
        Information
      </Text>
      <div className="grid grid-cols-3 gap-4 p-2">
        {informationNav.map(item => (
          <VerticalGroup
            key={item.name}
            center
            verticalCenter
            className="h-14 monitor:h-16"
          >
            <Clickable onClick={() => handleItemClick(item.href)}>
              <VerticalGroup center>
                <Icon
                  type={item.iconType}
                  colour="primary"
                  aria-hidden="true"
                  size={10}
                />
                <Text size="sm" weight="light" colour="primary">
                  {item.name}
                </Text>
              </VerticalGroup>
            </Clickable>
          </VerticalGroup>
        ))}
      </div>
      <Text weight="semibold" size="lg">
        Decisions
      </Text>
      <div className="grid grid-cols-3 gap-4 p-2">
        {decisionNav.map(item => (
          <VerticalGroup
            key={item.name}
            center
            verticalCenter
            className="h-14 monitor:h-16"
          >
            <Clickable onClick={() => handleItemClick(item.href)}>
              <VerticalGroup center>
                <Icon
                  type={item.iconType}
                  colour="primary"
                  aria-hidden="true"
                  size={10}
                />
                <Text size="sm" weight="light" colour="primary">
                  {item.name}
                </Text>
              </VerticalGroup>
            </Clickable>
          </VerticalGroup>
        ))}
      </div>
    </VerticalGroup>
  )
}

export default HelpContentList
