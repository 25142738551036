import React from 'react'
import Image from '../../atoms/Image/Image'
import { useAppSelector } from '../../../store'
import Swiper from '../../atoms/Swiper/Swiper' // Import your custom Swiper component
import { SwiperSlide } from 'swiper/react'
import HorizontalGroup from '../../atoms/HorizontalGroup/HorizontalGroup'
import LandscapeMobilePage from '../../atoms/LandscapeMobilePage/LandscapeMobilePage'
import Page from '../../atoms/Page/Page'
import { useNavigate } from 'react-router-dom'

const HelpManual = () => {
  const { files } = useAppSelector(state => ({
    totalPages: state.help.totalPages,
    files: state.help.files,
  }))
  const navigate = useNavigate()
  const handleClose = () => {
    navigate('/summary')
  }

  return (
    <Page className="flex h-full items-center justify-center text-center">
      <Swiper enableFractionPagination={true} className="mySwiper">
        {files.map((file, i) => (
          <SwiperSlide className="h-full" key={i}>
            <HorizontalGroup verticalCenter center fullHeight fullWidth>
              <Image
                src={file}
                className="tablet:w-10/12desktop:w-11/12 w-full p-4 pb-10 desktop:pb-7 monitor:w-[85%]"
              />
            </HorizontalGroup>
          </SwiperSlide>
        ))}
      </Swiper>

      <LandscapeMobilePage handleClose={handleClose}>
        <HorizontalGroup center verticalCenter fullWidth>
          <Swiper enableFractionPagination={true} className="">
            {files.map((file, i) => (
              <SwiperSlide className="h-full w-full" key={i}>
                <HorizontalGroup center fullHeight fullWidth className=" ">
                  <Image src={file} className="w-[470px]" />
                </HorizontalGroup>
              </SwiperSlide>
            ))}
          </Swiper>
        </HorizontalGroup>
      </LandscapeMobilePage>
    </Page>
  )
}

export default HelpManual
